import { Spinner } from 'react-bootstrap';

interface LoadingIndicatorProps {
    isLoading: boolean;
    showLabel?: boolean;
    size?: "sm"
    title?: string;
}

export const LoadingIndicator = ({ isLoading, showLabel = true, size, title = "Loading..." }: LoadingIndicatorProps) => {
    if (!isLoading) {
        return null;
    }
    return (
        <div
            style={{
                position: 'relative',
                zIndex: 1330,
                width: '100%',
                height: '100%',
                backgroundColor: 'transparent',
                top: 0,
                left: 0,
                textAlign: 'center',
            }}
        >
            <Spinner animation="border" role="form" size={size} />
            {showLabel &&
                <div className='row' style={{ marginTop: '10px', color: 'lightgray' }}>
                    {showLabel ?? (<span><h5>{title}</h5></span>)}
                </div>}
        </div >
    );
};
