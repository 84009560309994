import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import {
    Grid,
    GridColumn,
    GridColumnMenuFilter,
    GridColumnMenuProps,
    GridDataStateChangeEvent,
    GridNoRecords,
    GridPageChangeEvent,
    GridHeaderSelectionChangeEvent,
    getSelectedState,
    GridSortChangeEvent,
    GridSelectionChangeEvent
} from '@progress/kendo-react-grid';
import { DataResult, orderBy, process, SortDescriptor, State } from '@progress/kendo-data-query';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useLoadData } from '../../hooks/use-scheduler-load-data';
import { IInspector } from '../../models/scheduler/inspector';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { getter } from "@progress/kendo-react-common";
import { StorageKey, WINDOWS_DOORS_API_URL } from '../../constants';
import { Dialog } from '@progress/kendo-react-dialogs';
import mobileImage from '../../images/mobile-image.png';
import { useCurrentUser } from '../../hooks/user-current-user.hook';
import { useInspectors } from '../../api/inspector';
import { ScheduleInspector } from './components/schedule-inspecptor';
import { forEach, isEmpty } from 'lodash';
import { formatPhoneNumber } from './utils';

interface ICheckAvailabilityProps {
    onClose: () => void;
    show?: boolean;
    claimInfo: Scheduler;
    onAddOrDeleteSchedule: () => void

}

const CheckAvailability: React.FunctionComponent<ICheckAvailabilityProps> = ({ onClose, show, claimInfo, onAddOrDeleteSchedule }) => {
    const initialDataState: State = {
        take: 10,
        skip: 0,
    };
    const [gridData, setGridData] = useState<DataResult>({ data: [], total: 0 });
    const initialSort: Array<SortDescriptor> = [{ field: "name", dir: "asc" }];
    const [resultData, setResultData] = useState<DataResult>();
    const [dataStateArray, setDataStateArray] = useState<State>(initialDataState);
    const [sort, setSort] = useState(initialSort);
    const [sendLink, setSendLink] = useState(false);
    const [validateEmail, setValidateEmail] = useState(false);
    const [showScheduler, setShowScheduler] = useState(false);


    const DATA_ITEM_KEY = "userID";
    const SELECTED_FIELD = "selected";

    const { isLoading, isError, refetch: reload, data: result } = useInspectors({ variables: { claimID: claimInfo?.claimID } })

    const idGetter = getter(DATA_ITEM_KEY);
    const { fullName, userID } = useCurrentUser();
    const [isRequestLoading, setIsRequestLoading] = React.useState<boolean>(false);
    // Ensure selectedState only has boolean values
    const [selectedState, setSelectedState] = useState<{ [id: string]: boolean | number[] }>({});
    const [loadingRequest, setloadingRequest] = useState(false);
    const [selectedInspector, setSelectedInpector] = useState<any>([]);
    // Process result data and update grid data
    useEffect(() => {
        // Check if the drawer is opened
        // Call the reload function to fetch data
        if (result) {
            const processedData = result.map((dataItem: IInspector) =>
                Object.assign({ selected: false }, dataItem)
            );
            setGridData(process(processedData, dataStateArray));
        }
    }, [result, dataStateArray]);


    // Handle search input changes
    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchKey = e.target.value?.toLowerCase();
        if (searchKey) {
            const filteredData = result?.filter(p =>
                p.inspector_Name?.toLowerCase().includes(searchKey) ||
                p.inspector_Email?.toLowerCase().includes(searchKey) ||
                p.inspector_Phone?.toLowerCase().includes(searchKey)
            ) || [];
            setGridData(process(filteredData, dataStateArray));
        } else {
            setGridData(process(result || [], dataStateArray));
        }
    };

    const ColumnMenuFilter = (props: GridColumnMenuProps) => (
        <div>
            <GridColumnMenuFilter {...props} />
        </div>
    );

    // Handle row selection change
    const onSelectionChange = (
        (event: GridSelectionChangeEvent) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            let selectedIds = []
            for (const property in newSelectedState) {
                if (newSelectedState[property]) {
                    let insItem: IInspector[] = result?.filter(item => item?.userID === parseInt(property));
                    if (insItem?.length > 0) {
                        let inspectorName = insItem.map(item => item?.inspector_Name);
                        selectedIds.push(selectedIds.length > 0 ? "," + inspectorName : inspectorName);
                    }
                }
            }
            setSelectedInpector(selectedIds);
            //setSelectedInpector(selectedIds)
            setSelectedState(newSelectedState);
        }
    );

    // Handle header (select all) checkbox change
    const onHeaderSelectionChange = useCallback(
        (event: GridHeaderSelectionChangeEvent) => {
            const isSelected = (event.syntheticEvent.target as HTMLInputElement).checked;
            const newSelectedState: { [id: string]: boolean } = {};

            event.dataItems.forEach((item) => {
                newSelectedState[idGetter(item)] = isSelected;
            });
            setSelectedInpector([]);
            setSelectedState(newSelectedState);
        },
        [idGetter]
    );

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        const newDataState = process(result || [], event.dataState);
        setResultData(newDataState);
        setDataStateArray(event.dataState);
    };

    const handleDataStateChange = (e: GridPageChangeEvent) => {
        const newDataState = { skip: e.page.skip, take: e.page.take };
        setDataStateArray(newDataState);
        setGridData(process(result || [], newDataState));
    };
    const toggleDialog = () => {
        setSendLink(false);
    };
    const toggleDialogValidation = () => {
        setValidateEmail(false);
    };

    const onRequestInvitationSend = async () => {

        // Extract selected rows
        const selectedItems = result.filter(item => selectedState[idGetter(item)]);

        if (selectedItems?.length > 0) {
            const authState = JSON.parse(localStorage.getItem(StorageKey.AuthState));

            // Format userIds and emailIds as comma-separated strings without quotes
            const userIds = selectedItems.map(item => item.userID).join(',');
            const emailIds = selectedItems.map(item => item.inspector_Email).join(',');
            const userNames = selectedItems.map(item => item.inspector_Name).join(',');
            setloadingRequest(true);
            setIsRequestLoading(true);
            const url = `${WINDOWS_DOORS_API_URL}/api/SchedulerAssignments/RequestInspectorAvailability?schedulerId=${userID}&schedulerName=${fullName}&claimNumber=${claimInfo.claimNo}&claimId=${claimInfo.claimID}&UserIds=${userIds}&EmailIds=${emailIds}&userNames=${userNames}`;
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...(authState && { Authorization: `Bearer ${authState}` }),
                },
            };
            await fetch(url, requestOptions)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json(); // Adjust based on expected response type
                })
                .then(data => {
                    console.log('Success:', data);
                    setSendLink(true);
                    setloadingRequest(false);
                    setIsRequestLoading(false);
                    // Handle success response
                })
                .catch(error => {
                    console.error('Error:', error);
                    setloadingRequest(false);
                    setIsRequestLoading(false);
                    // Handle error
                });
        } else {
            setValidateEmail(true);
        }
    }


    const selectedInspectorIndexRef = React.useRef<number>()

    return (
        <>
            <Offcanvas show={show} onHide={onClose} placement='end'>
                <div className='m-4'>
                    <div className='card grayBg p-3 border-0 sw-shadow radius-15'>
                        {loadingRequest && <div className='d-flex' style={{ zIndex: 1001 }} ><LoadingIndicator title='Please wait, Sending invitation..' isLoading /></div>}
                        <div className='d-flex'>
                            <div className='col'>
                                <p className='mb-1 text-secondary'>Claim#: </p>
                                <h6 className='indication mb-0'> {claimInfo?.claimNo}</h6>
                                <p className='mb-0'>{claimInfo?.homeOwner}</p>
                            </div>
                            <div className='col ps-4 border-start'>
                                <p className='mb-1 text-secondary'>Address: </p>
                                <p className='mb-0'>{claimInfo?.address}</p>
                            </div>
                            <div className='col ps-4 border-start'>
                                <p className='mb-1 text-secondary'>Phone: </p>

                                {claimInfo.homeOwner_Phone && <p className='mb-0'> {claimInfo?.homeOwner_Phone}</p>}

                            </div>
                        </div>
                    </div>

                    <div className='d-flex justify-content-between align-items-center mt-2 mb-2'>
                        <div>
                            {selectedInspector.length === 0 ? (<h6 className='mb-0 indication'>INSPECTORS</h6>) : <h6 className='mb-0 indication'>INSPECTORS <span style={{ fontSize: "12px", fontWeight: "normal" }}>Selected Count ({selectedInspector.length}) </span></h6>}
                            <div className='d-flex justify-content-start'>
                                <span>{selectedInspector}</span>
                            </div>
                        </div>
                        {validateEmail &&
                            <Dialog title={"Validation"} onClose={toggleDialogValidation} style={{ borderRadius: "10px" }} className="delete-dialog">
                                <div>
                                    <div className="row">
                                        <div className="d-flex justify-content-between align-items-center mt-2 mb-2'">
                                            <p style={{ fontSize: "16px", fontWeight: "bold" }}>Select one or more inspector(s) to send the request.</p>
                                        </div>
                                    </div>
                                </div>
                            </Dialog>
                        }
                        {sendLink &&
                            <Dialog width="30%" title={" "} onClose={toggleDialog} style={{ borderRadius: "10px" }} className="delete-dialog">
                                <div>
                                    <div className="row">
                                        <div className="col-3">
                                            <img src={mobileImage} />
                                        </div>
                                        <div className="col-9 justify-content-between">
                                            <h5 className="justify-content-center mt-4" style={{ textAlign: "center" }} >Availability request has been
                                                sent to the selected inspectors</h5>
                                        </div>
                                    </div>
                                </div>
                            </Dialog>
                        }

                        <div className='d-flex justify-content-end'>
                            <div><input
                                type="text"
                                placeholder="Inspector Name"
                                className="form-control search-right-align searchBg"
                                style={{ padding: '0.2rem 0.75rem' }}
                                onChange={onSearch}
                            /></div>
                            <div><button onClick={() => {
                                onRequestInvitationSend()
                            }
                            } className="btn-primary btn-sm btn ms-2 text-nowrap" type="submit">
                                {/* Request Availability */}
                                {isRequestLoading ? <LoadingIndicator size='sm' showLabel={false} isLoading={isRequestLoading} /> : "REQUEST AVAILABILITY"}
                            </button></div>
                            <div><button className="btn-secondary btn ms-2 btn-sm" onClick={() => reload()} type="button">
                                <svg width="15" height="15" viewBox="0 0 19 21">
                                    <path id="Path_reload" data-name="Path / reload" d="M2,12a9,9,0,0,0,9,9,9.222,9.222,0,0,0,6.4-2.6l-1.5-1.5A6.706,6.706,0,0,1,11,19a7,7,0,1,1,7-7H15l4,4h.1L23,12H20A9,9,0,0,0,2,12Z" transform="translate(-2 -3)" fill="#fff" />
                                </svg>
                            </button></div>
                        </div>
                    </div>
                    <div className='card p-3 border-0 sw-shadow radius-15'>
                        {isError && <div>Error</div>}
                        {isLoading && <LoadingIndicator isLoading />}
                        {!isLoading && (
                            <Grid
                                data={orderBy(gridData.data.map((item) => ({
                                    ...item,
                                    [SELECTED_FIELD]: selectedState[idGetter(item)] || false,
                                })), sort)}
                                pageable={{ pageSizes: [10, 20, 50, 100] }}
                                skip={dataStateArray.skip}
                                take={dataStateArray.take}
                                total={gridData.total}
                                sortable={true}
                                dataItemKey={DATA_ITEM_KEY}
                                selectedField={SELECTED_FIELD}
                                onDataStateChange={dataStateChange}
                                {...dataStateArray}
                                sort={sort}
                                onSortChange={(e: GridSortChangeEvent) => setSort(e.sort)}
                                onPageChange={handleDataStateChange}
                                onSelectionChange={onSelectionChange}
                                onHeaderSelectionChange={onHeaderSelectionChange}
                            >
                                <GridColumn
                                    field={SELECTED_FIELD}
                                    width="40px"
                                    headerSelectionValue={
                                        gridData.data.length > 0 && gridData.data.every((item) => selectedState[idGetter(item)])
                                    }
                                />
                                <GridColumn columnMenu={ColumnMenuFilter} field="inspector_Name" title="Inspector" />
                                <GridColumn field="inspector_Phone" title="Phone#" cell={(props) => {

                                    return <td className={props.className}>{!!props.dataItem?.[props.field] && formatPhoneNumber(props.dataItem[props.field])}</td>
                                }} />
                                <GridColumn field="inspector_Email" title="Email" />
                                <GridColumn field="distance" title="Distance" />
                                <GridColumn field="assignedinspections" title="Assigned Inspection" />
                                <GridColumn field="requestsPending" title="Requests Pending" />
                                <GridNoRecords>
                                    <div style={{ width: "100%", height: "500px" }}>
                                        <center>No data found</center>
                                    </div>
                                </GridNoRecords>
                                <GridColumn field="schedule" title="Schedule" cell={(props) => {



                                    return (
                                        <button className="btn-primary btn-sm text-capitalize m-2" onClick={() => {


                                            const dataIndex = result?.findIndex(inspector => inspector.userID === props.dataItem?.userID)
                                            selectedInspectorIndexRef.current = dataIndex;
                                            setShowScheduler(true)
                                        }}>Schedule</button>
                                    )
                                }} />
                            </Grid>
                        )}
                    </div>
                </div>
            </Offcanvas >
            {showScheduler && <ScheduleInspector show={showScheduler} inspectorIndex={selectedInspectorIndexRef.current} claim={claimInfo} onClose={() => setShowScheduler(false)} onAddOrDeleteSchedule={onAddOrDeleteSchedule} />}
        </>
    );
};

export default CheckAvailability;
