import * as React from 'react';
import { useLoadData } from '../../../hooks/use-scheduler-load-data';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { getFormSubmissionInfo } from 'react-router-dom/dist/dom';
import { string } from 'yup';
import { useState } from 'react';

interface IDashboardCountProps {
    onCancelFilterToggle: (status: string) => void;
    onCancelDateToggle: (status: string) => void;
}

const DashboardCount: React.FunctionComponent<IDashboardCountProps> = (props) => {

    const { isError, isLoading, reload, result } = useLoadData<ISchedulerCount[]>("SchedulerAssignments/GetSchedulerDashboardCounts");
    const { onCancelFilterToggle, onCancelDateToggle } = props;
    const [filter, setFilter] = useState<string>("");
    const active = "active-indicator gredientBg card p-4 border-0 sw-shadow radius-15 border-0";
    const inActive = "card p-4 border-0 sw-shadow radius-15 border-0";

    return (<>
        {isLoading && (<LoadingIndicator isLoading />)}
        {!isLoading && result && <>
            <div className='boxcount mb-4'>
                <div className='gredientBg card p-4 border-0 sw-shadow radius-15 border-0 '>
                    <h5 className='text-uppercase  '>Upcoming <span className='fw-bold text-primary'> Inspections</span></h5>
                    <div className='gridTd' >
                        <div style={{ cursor: "pointer" }} className={filter === "today" && ('active-indicator')} onClick={() => {
                            setFilter("today")
                            onCancelDateToggle("today")
                        }}>
                            <h3 className='indication'>  {result[0].upinS_Count_Today}</h3>
                            <p className='mb-0 text-nowrap' >Today</p>
                        </div>
                        <div style={{ cursor: "pointer" }} onClick={() => { setFilter("tomorrow"); onCancelDateToggle("tomorrow") }} className={filter === "tomorrow" && ('active-indicator')}>
                            <h3 className='indication'> {result[0].upinS_Count_Tomorrow}</h3>
                            <p className='mb-0 text-nowrap'>Tomorrow</p>
                        </div>
                        <div style={{ cursor: "pointer" }} className={filter === "thisweek" && ('active-indicator')} onClick={() => { setFilter("thisweek"); onCancelDateToggle("thisweek") }}>
                            <h3 className='indication'> {result[0].upinS_Count_Week}</h3>
                            <p className='mb-0 text-nowrap'>This week</p>
                        </div>
                    </div>
                </div>
                <div style={{ cursor: "pointer" }} className={filter === "pendinginspections" ? (active) : (inActive)} onClick={() => { filter != "pendinginspections" ? setFilter("pendinginspections") : setFilter("empty"); onCancelFilterToggle("pending"); }}>
                    <h5 className='text-uppercase'>Pending <span className='fw-bold'>Inspections</span></h5>
                    <h1 className='indication2'>{result[0].pendinS_Count}</h1>
                </div>
                <div style={{ cursor: "pointer" }} className={filter === "reschedulingrequests" ? (active) : (inActive)} onClick={() => { filter != "reschedulingrequests" ? setFilter("reschedulingrequests") : setFilter("empty"); onCancelFilterToggle("Rescheduled"); }} >
                    <h5 className='text-uppercase'>Rescheduling  <span className='fw-bold'>Requests</span></h5>
                    <h1 className='indication2'>{result[0].resreQ_Count}</h1>
                </div>
                <div style={{ cursor: "pointer" }} className={filter === "cancelled" ? (active) : (inActive)} onClick={() => { filter != "cancelled" ? setFilter("cancelled") : setFilter("empty"); onCancelFilterToggle("cancelled"); }}>
                    <h5 className='text-uppercase fw-bold'>Cancelations</h5>
                    <h1 className='indication2'>{result[0].canC_Count}</h1>
                </div>

            </div ></>}
        {isError && (<div>Error Loading Count</div>)}

    </>);
};

export default DashboardCount;
