import * as React from 'react';
import { useLoadData } from '../../hooks/use-scheduler-load-data';
import {
    IBasicDetails,
    InspectorLandingInterface,
    InspectorSlot,
} from '../../models/scheduler/inspector-landing';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { parseDate, formatDate } from '@progress/kendo-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { forEach, isEmpty } from 'lodash';
import { stat } from 'fs';
import { WINDOWS_DOORS_API_URL } from '../../constants';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import axios from 'axios';
import CheckboxGroup, { ICheckboxItemProps } from '../../components/checkbox-group-field';
import { convertToTimezoneWithDateString } from '../../utils';

interface IInspectorLandingProps { }

const InspectorLanding: React.FunctionComponent<IInspectorLandingProps> = (props) => {
    const nav = useNavigate();
    const loc = useLocation();
    const { state } = loc;


    const { isError, isLoading, reload, result } = useLoadData<InspectorLandingInterface[]>(
        'SchedulerAssignments/GetInspectorLandingDetails?InspectorID=' + state?.schUsers?.userId
    );
    const [data, setData] = React.useState<{ b: IBasicDetails[]; slot: InspectorSlot[] }>({
        b: undefined,
        slot: undefined,
    });
    const [accept, setAccept] = useState<{
        isAccept: boolean;
        data: { b?: IBasicDetails[]; inspSlots?: string; selctedTimeSlot?: ICheckboxItemProps[] };
    }>({ data: undefined, isAccept: false });
    const [dateValidation, setDateValidation] = useState<boolean>(false);
    let basicDetails: IBasicDetails[] = [];
    let inspectorSlots: InspectorSlot[][] = [];
    let slots: InspectorSlot[] = [];
    const [isLoadData, setLoadData] = useState<boolean>(false);

    const toggleDialogValidation = () => {
        setDateValidation(false);
    };


    React.useEffect(() => {

        if (result) {
            result.forEach((e) => {

                if (e.basicDetails) {
                    basicDetails.push(e.basicDetails);
                } else {
                    inspectorSlots.push(e.inspectorSlots);
                    //inspectorSlots.push([{ claimID: 3, claimNo: "C234", dateFrom: "2024-10-07 01:30 pm", dateTo: "2024-10-07 01:30 pm", location: "ertodfe", slotID: "2", timeSlot: "01:30pm-02:30pm" }])
                    //data.slot.push()
                }
                // inspectorSlots.push([{ claimID: 3, claimNo: "C234", dateFrom: "2024-10-07 11:00 am", dateTo: "2024-10-07 12:30 pm", location: "Erode", slotID: "1", timeSlot: "11:30am-12:30pm" }]);
                // inspectorSlots.push([{ claimID: 3, claimNo: "C234", dateFrom: "2024-10-07 01:30 pm", dateTo: "2024-10-07 02:30 pm", location: "Erode", slotID: "2", timeSlot: "01:30pm-02:30pm" }]);
                // inspectorSlots.push([{ claimID: 3, claimNo: "C234", dateFrom: "2024-10-07 03:30 pm", dateTo: "2024-10-07 04:30 pm", location: "Erode", slotID: "3", timeSlot: "03:30pm-04:30pm" }]);
                // inspectorSlots.push([{ claimID: 3, claimNo: "C234", dateFrom: "2024-10-07 04:30 pm", dateTo: "2024-10-07 05:30 pm", location: "Erode", slotID: "4", timeSlot: "04:30pm-05:30pm" }]);
            });
            slots = inspectorSlots.flat();

            setData({ b: basicDetails, slot: slots });
            console.log(slots)
        }
    }, [result]);

    const handleAccept = async () => {

        const CLAIM_ID = accept.data?.b[0].claimID;
        const CLAIM_NO = accept.data?.b[0].claimNo;
        const INSPECTOR_ID = state?.schUsers?.userId;
        const slotsvalueList = accept.data?.inspSlots;

        if (CLAIM_ID > 0 && INSPECTOR_ID > 0 && slotsvalueList?.length > 0) {
            setLoadData(true);
            const response = await axios.post(`SchedulerAssignments/InspectorLandingSlotAccept?schedulerId=0&claimId=${CLAIM_ID}&claimNumber=${CLAIM_NO}&inspectorId=${INSPECTOR_ID}&slotIds=${encodeURIComponent(slotsvalueList)}`)
            if (response.data.result === -1) {
                setAccept({ data: undefined, isAccept: false })
            } else {
                console.log(response.status)
            }
            reload();
            setLoadData(false);
        } else {
            console.log("Parameter Missing..")
            setLoadData(false);
        }
    };


    return (
        <>
            {isLoading && <LoadingIndicator isLoading />}
            {isError && (
                <>
                    <div>Error Loading Landing Details</div>
                </>
            )}
            {dateValidation && (
                <Dialog
                    title={'Validation'}
                    onClose={toggleDialogValidation}
                    style={{ borderRadius: '10px' }}
                    className="delete-dialog"
                >
                    <div>
                        <div className="row">
                            <div className="d-flex justify-content-between align-items-center mt-2 mb-2'">
                                <p style={{ fontSize: '17px' }}>
                                    Select time slot(s) to accept.
                                </p>
                            </div>
                        </div>
                    </div>
                </Dialog>
            )}
            {!isLoading && result && (
                <div className="m-2 p-2">
                    <div className="d-flex justify-content-between align-items-center mb-3 ">
                        <div className='font-bold' >{'job offers'.toUpperCase()}</div>

                        <button
                            className="btn btn-light d-flex align-items-center sw-shadow"
                            onClick={() => {
                                nav('/inspector/inspector-availability', { state: state });
                            }}
                        >
                            <svg
                                className="me-2"
                                width="14.25"
                                height="15.833"
                                viewBox="0 0 14.25 15.833"
                            >
                                <path
                                    id="Path_calendar-outline"
                                    data-name="Path / calendar-outline"
                                    d="M15.667,3.583h-.792V2H13.292V3.583H6.958V2H5.375V3.583H4.583A1.583,1.583,0,0,0,3,5.167V16.25a1.583,1.583,0,0,0,1.583,1.583H15.667A1.583,1.583,0,0,0,17.25,16.25V5.167a1.583,1.583,0,0,0-1.583-1.583m0,12.667H4.583V8.333H15.667V16.25m0-9.5H4.583V5.167H15.667V6.75m-5.542,3.958h3.958v3.958H10.125Z"
                                    fill="#606060"
                                    transform="translate(-3 -2)"
                                />
                            </svg>
                            My Availability
                        </button>
                    </div>

                    <div className='rescol-2'>
                        {data &&
                            data?.b?.map((e) => (

                                <>

                                    <div className="gredientBg card p-4 border-0 sw-shadow radius-15 border-0 ">
                                        <LoadingIndicator isLoading={isLoadData} ></LoadingIndicator>
                                        <div className="res-grd">
                                            <div>
                                                <p className="mb-1 text-secondary">
                                                    Scope of Work
                                                </p>
                                                <h6 >{e?.scopeOfWork}</h6>
                                            </div>
                                            <div>
                                                <p className="mb-1 text-secondary">Due</p>
                                                <h6 className="text-danger">
                                                    24 hours
                                                </h6>
                                            </div>
                                            <div>
                                                <p className="mb-1 text-secondary">Location</p>
                                                <h6 >{e?.location ?? '-'}</h6>
                                            </div>


                                        </div>

                                        {data.slot.filter((s) => s.claimID === e.claimID)
                                            .length > 0 ? (

                                            <>
                                                <div>
                                                    <label className="mb-2 mt-2 text-secondary">
                                                        Date Time
                                                    </label>
                                                    {data.slot.filter(s => s.claimID === e.claimID).length > 1 && (

                                                        <CheckboxGroup items={data.slot.filter(s => s.claimID === e.claimID).map((f, i) => ({
                                                            label: `${convertToTimezoneWithDateString(f?.dateFrom).format('MM/DD/YYYY hh:mm A')} - ${convertToTimezoneWithDateString(f?.dateTo).format('hh:mm A')}`,
                                                            value: f.slotID,
                                                            isUnAvailable: f.isUnavailable

                                                        }))} onchange={(selected: ICheckboxItemProps[]) => {
                                                            setAccept({ data: { b: [e], inspSlots: selected?.map((item) => parseInt(item.value))?.join(","), selctedTimeSlot: selected }, isAccept: false })
                                                        }}></CheckboxGroup>)}

                                                    {data.slot.filter(s => s.claimID === e.claimID).length === 1 && (data.slot.filter(s => s.claimID === e.claimID).map((f, i) => {
                                                        return <>
                                                            {`${convertToTimezoneWithDateString(f?.dateFrom).format('MM/DD/YYYY hh:mm A')} - ${convertToTimezoneWithDateString(f?.dateTo).format('hh:mm A')}`}
                                                        </>
                                                    }))}
                                                </div>
                                                <div className='gredientcardFooter'>

                                                    <a
                                                        className="text-decoration-underline d-block "
                                                        style={{ color: 'orange', cursor: "pointer" }}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            nav('/inspector/inspector-availability', { state: state });
                                                        }}
                                                    >
                                                        Provide New
                                                    </a>
                                                    <button
                                                        onClick={() => {
                                                            if (accept?.data?.inspSlots.length > 0) {
                                                                setAccept({ isAccept: true, data: accept.data });
                                                                //handleAccept;
                                                            } else {
                                                                setDateValidation(true);
                                                            }

                                                        }}
                                                        className="btn btn-primary btn-md text-capitalize"
                                                    >
                                                        Accept
                                                    </button>
                                                </div>
                                            </>
                                        ) : (
                                            <div className='gredientcardFooter'>
                                                <div className="scheduler-arrow-right" >
                                                    <a

                                                        style={{ color: 'orange' }}
                                                        className="text-decoration-underline"
                                                        onClick={(e) => {
                                                            e.preventDefault()

                                                            nav('/inspector/inspector-availability', { state: state });
                                                        }}
                                                    >
                                                        Provide Availability
                                                    </a>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                </>
                            ))}
                    </div>

                    {isEmpty(result) && (
                        <div>
                            <h4>No Record Found..</h4>
                        </div>
                    )}
                </div >
            )}
            {accept.isAccept && (<Dialog title={"Accept the Time Slot"} onClose={() => { setAccept({ data: accept.data, isAccept: false }); }}>
                <center className='gredientbg'>
                    <div className="row gredientbg">
                        <div className="d-flex justify-content-between">
                            <div className='ms-4'>
                                <center>
                                    Location
                                    <br />
                                    <h5>{result[0].basicDetails.location}</h5>
                                </center>
                            </div>
                            <hr style={{ borderLeft: "1px solid lightgrey" }} />
                            <div className='me-5'>
                                <center>
                                    Scope Of Work
                                    <br />
                                    {<span > {result[0].basicDetails.scopeOfWork}</span>}
                                </center>
                            </div>
                        </div>
                    </div>
                    <hr />

                    <div>
                        <h3 className='text-primary'>Thank you!</h3>
                        <br />
                        Please confirm that you wish to select the below time slot(s) for
                        <br />
                        the home inspection. Once approved, the inspector will be
                        <br />
                        notified, and you will receive a confirmation email.
                        <br />
                        <div className='mt-4'>
                            Selected time slot(s)
                            <br />
                            <p className=' mt-2' >{accept?.data?.selctedTimeSlot?.map((item) => item.label).join(",  ")}</p>
                        </div>
                    </div>
                    {/* <h3 className='text-primary'>Thank you!</h3>
                    <br />
                    Please confirm that you wish to select the below time slot for
                    <br />
                    the home inspection. Once approved, the homeowner will be
                    <br />
                    notified, and you will receive a confirmation email.
                    <br />
                    Selected time slot
                    <br />
                    <h4>  {accept.data.inspSlots.timeSlot}</h4> */}


                </center>

                <DialogActionsBar>
                    <div className='row'>
                        <div className='d-flex justify-content-between' >
                            <button className='btn btn-sm btn-secondary' onClick={() => {
                                setAccept({ data: accept.data, isAccept: false });
                            }}>Cancel</button>
                            <button className='btn btn-primary btn-sm' onClick={async () => {
                                if (data.slot.filter(e => data.b[0].claimID === e.claimID).length == 1) {
                                    accept.data.inspSlots = data.slot[0].slotID
                                }
                                await handleAccept();
                                // console.log('====================================');
                                // console.log({ axios });
                                // console.log('====================================');
                                // const response = await axios.post(`SchedulerAssignments/InspectorLandingSlotAccept?schedulerId =${state?.schUsers?.userId} & claimId=${accept.data.b[0].claimID} & claimNumber=${accept.data.b[0].claimNo} & inspectorId=${state?.schUsers.userId} & slotIds=${accept.data.inspSlots}`)
                                // if (response.data.result === -1) {
                                //     setAccept({ data: undefined, isAccept: false })
                                //     reload();
                                // }
                            }}>Accept</button>

                        </div>
                    </div>


                    {/* <button className='btn btn-sm btn-primary ms-auto me-1' onClick={async () => {
                        const response = await axios.post(`SchedulerAssignments / InspectorLandingSlotAccept ? schedulerId = ${ state?.schUsers?.userId } & claimId=${ accept.data.inspSlots.claimID } & claimNumber=${ accept.data.b[0].claimNo } & inspectorId=${ state?.schUsers.userId } & slotId=${ accept.data.inspSlots.slotID }`)
                        if (response.data.result === -1) {
                            setAccept({ data: undefined, isAccept: false })
                            reload();
                        }
                    }}>Accept</button> */}
                </DialogActionsBar>
            </Dialog >)
            }
        </>
    );
};

export default InspectorLanding;
