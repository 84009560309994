
import * as React from 'react';
import { useLoadData } from '../../hooks/use-scheduler-load-data';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { useLocation, useNavigate } from 'react-router-dom';
import EditIcon from '../../images/edit';
import CloseIcon from '../../images/close-icon';
import { boolean } from 'yup';
import { useEffect, useRef, useState } from 'react';
import AddAvailability from './components/add-inspector-availability';
import { IHomeOwnerAvailability } from "../../models/scheduler/home-owner-availability"
import { ISaveTimeSlot } from "../../models/scheduler/save-time-slot"
import { isEmpty } from 'lodash';
import { formatDate, parseDate } from '@progress/kendo-intl';
import TimeSlot, { ISlot } from './components/timeslot-component';
import { DeleteModelDialog } from '../../components/delete-model-dialog.component';
import { usePostData } from '../../hooks/use-post-data.hook';
import axios from 'axios';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import dayjs from 'dayjs';
import { convertToTimezoneWithDateString } from '../../utils';

interface IHomeOwnerAvailabilityProps {
}

const HomeOwnerAvailability: React.FunctionComponent<IHomeOwnerAvailabilityProps> = (props) => {
    const nav = useNavigate();
    const loc = useLocation();
    const { state } = loc;
    const unAvailableDaySlots = useRef<ISlot[]>([])
    const unAvailableTimeSlots = useRef<ISlot[]>([])
    const { schUsers, Claim } = state;
    const { userGuid, userId, userName } = schUsers;
    const { claimNo, claimID, homeOwner_Name,
        homeowner_Email,
        homeOwner_Phone,
        homeOwner_Address } = Claim;

    const [del, setDel] = useState<{ isDel: boolean, deleteData?: IHomeOwnerAvailability }>({ deleteData: undefined, isDel: false })
    const initialSlotData = useRef<ISaveTimeSlot>({
        claimNo: claimNo, isAvailable: true, schID: 0, sDate: "", timeSlot: "", userGUID: userGuid, claimId: claimID, userId: userId, homeOwnerId: userId, dateSlots: []
    });


    const { isError, isLoading, reload, result } = useLoadData<IHomeOwnerAvailability[]>(`SchedulerAssignments/GetHomeOwnerAvailability?HomeownerID=${userId}`)
    const [addNew, setAddNew] = useState<boolean>(false);




    return (<>
        {isLoading && (<LoadingIndicator isLoading />)}
        {isError && (<div>
            Error Loading Content...
        </div>)}

        {claimNo && (<div >

            <div className='p-4'>
                <div className="grid-userdetails">
                    <div>
                        <div className='indication'>{!isEmpty(homeOwner_Name) && (homeOwner_Name.toUpperCase())}</div>
                        <div>{homeOwner_Address}</div>
                        <div>{homeOwner_Phone}</div>
                    </div>
                    <div>
                        {!isEmpty(claimNo) ? (
                            <><div className='indication'>{claimNo}</div>
                                <div>Claim#</div></>) : (null)}
                    </div>
                </div>
            </div>
            <div className='m-3 p-2'>
                <div className="p-3 card border-0 mb-1 mb-lg-4 mt-1 sw-shadow radius-15" >
                    <div>
                        <h5>
                            INSTRUCTIONS
                        </h5>
                        <div>

                            <p>
                                Please select your preferred time slot for your inspection from the options provided below. If none of the available slots work for you, click the "Decline and Provide New" button to suggest alternative dates and times. Thank you!
                            </p>

                        </div>

                    </div>
                </div>
            </div>
            <div className='card p-4 p-4  p-lg-5 pt-lg-3 border-0 radius-page-15 sw-shadow ' style={{ minHeight: 'Calc(100vh - 170px)' }}>

                <div className="d-flex">
                    <button style={{ display: "none" }} className="backbtn" onClick={() => { nav('/homeowner/homeowner-landing', { state: schUsers }) }}>
                        Back
                    </button>
                    <button onClick={() => {
                        let days = result.map(e => e.dateFrom);
                        let dayslots: ISlot[] = days.map(e => { return { label: formatDate(parseDate(e), "EE,MM/dd/yyyy"), value: formatDate(parseDate(e), "MM/dd/yyyy") } })
                        unAvailableDaySlots.current = dayslots;
                        let time: ISlot[] = result.map(e => { return { label: e.timeSlot, value: e.timeSlot } })
                        unAvailableTimeSlots.current = time;

                        setAddNew(true);
                    }} className=" d-flex justify-content-end btn-primary btn-sm ms-auto mb-3">
                        Add New Time Slot
                    </button></div>
                <div>
                    <div className='responsivfix-3colm'>
                        {result?.map(e => (
                            <div className={e.isAvailable === 1 ? "shadow-md rounded-3 p-3 m-1" : "shadow-md rounded-3 p-3 m-1"} style={e?.slotstatus === "Scheduled" ? { backgroundColor: "#eeeeee" } : e.isAvailable === 1 ? { backgroundColor: "#D1FFBD" } : { backgroundColor: "#FFCCCB" }}>
                                <div className="inner-available-grid homeowner">
                                    <div className='subgrid'>{convertToTimezoneWithDateString(e?.dateFrom).format("MM/DD/YYYY hh:mmA")}-{convertToTimezoneWithDateString(e?.dateTo).format("hh:mmA")}</div>
                                    <div style={{ color: e.isAvailable === 0 ? 'red' : '#04A81F' }}>{e?.slotstatus === "Scheduled" ? <h5 className='m-0'>Scheduled</h5> : e.isAvailable === 0 ? <h5 className='m-0'>Unavailable</h5> : <h5 className='m-0'>Available</h5>}</div>
                                    <div
                                        onClick={() => {
                                            initialSlotData.current = { claimNo: e.claimNo, isAvailable: e.isAvailable === 1, schID: 0, sDate: e.dateFrom, timeSlot: e.timeSlot, userGUID: userGuid, claimId: 0, userId: userId, dateSlots: [] }
                                            setAddNew(true);
                                        }}
                                    ></div>
                                    {e?.slotstatus !== "Scheduled" ?
                                        (<div onClick={() => { setDel({ isDel: true, deleteData: e }) }} className="home-margin" style={{ cursor: "pointer" }}><CloseIcon /></div>) :
                                        (<></>)
                                    }




                                </div>


                            </div>
                        ))}

                    </div>
                </div>
            </div>
        </div >
        )}
        {
            addNew && (<AddAvailability data={initialSlotData.current} closeDialog={() => {
                setAddNew(false);
            }} onSuccess={() => {
                reload();
            }}
                unAvailableDaySlots={unAvailableDaySlots.current}
                unAvailableTimeSlots={unAvailableTimeSlots.current} />)
        }
        {/* {del.isDel && (<Dialog title='Delete Slot?' onClose={() => { setDel({ isDel: false, deleteData: undefined }) }} key={1}>

            <center>
                Are you sure to delete.?
            </center>
            <DialogActionsBar>
                <button className='btn btn-secondary btn-sm ms-auto me-1' onClick={async () => {
                    setDel({ isDel: false, deleteData: undefined })


                }}

                >
                    Cancel
                </button>
                <button className='btn btn-primary btn-sm me-1' onClick={async () => {
                    const response = await axios.post(`SchedulerAssignments/DeleteHomeOwnerSlotsForCalendar?homeownerId=${userId}&slotId=${del.deleteData.slotId}&userId=${userId}&userName=${userName}&claimNumber=${claimNo}`);
                    if (response.data.result === -1) {
                        setDel({ isDel: false, deleteData: undefined });
                        reload();
                    }

                }}>OK</button>
            </DialogActionsBar>
        </Dialog>)} */}

        {
            del.isDel && (<Dialog title='Delete Slot' width={"30%"} onClose={() => { setDel({ isDel: false, deleteData: undefined }) }} key={1}>
                <center>
                    <h5>Are you sure you want to delete ?</h5>
                </center>
                <DialogActionsBar>
                    <div className='row'>
                        <div className='d-flex justify-content-between' >
                            <button className='btn btn-sm btn-secondary' onClick={() => {
                                setDel({ isDel: false, deleteData: undefined })
                            }}>No</button>
                            <button className='btn btn-primary btn-sm' onClick={async () => {
                                const response = await axios.post(`SchedulerAssignments/DeleteHomeOwnerSlotsForCalendar?homeownerId=${userId}&slotId=${del.deleteData.slotId}&userId=${userId}&userName=${userName}&claimNumber=${claimNo}`);
                                if (response.data.result === -1) {
                                    setDel({ isDel: false, deleteData: undefined });
                                    reload();
                                }
                            }}>Yes</button>

                        </div>
                    </div>
                    {/* <button className='btn btn-secondary btn-sm ms-auto me-1' onClick={async () => {
                        setDel({ isDel: false, deleteData: undefined })


                    }}

                    >
                        Cancel
                    </button>
                    <button className='btn btn-primary btn-sm me-1' onClick={async () => {
                        const response = await axios.post(`SchedulerAssignments/DeleteInspSlotsForCalendar?inspectorId=${userId}&slotId=${del.deleteData.slotId}&userId=${userId}&userName=${userName}&claimNumber=${del.deleteData.claimNo}`);
                        if (response.data.result === -1) {
                            setDel({ isDel: false, deleteData: undefined });
                            reload();
                        }

                    }}>OK</button> */}
                </DialogActionsBar>




            </Dialog>)
        }


    </>);
};

export default HomeOwnerAvailability;
