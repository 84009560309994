import { Checkbox } from '@progress/kendo-react-inputs';
import * as React from 'react';
import ExclamationIcon from '../images/exclamation';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

interface ICheckboxGroupProps {
    items: ICheckboxItemProps[];
    onchange: (selected: ICheckboxItemProps[]) => void;
}
export interface ICheckboxItemProps {
    label: string;
    value: any;
    isUnAvailable?: number;
}
const CheckboxGroup: React.FunctionComponent<ICheckboxGroupProps> = (props) => {
    const { items, onchange } = props;
    const [selectedItems, setSelectedItems] = React.useState<ICheckboxItemProps[]>([])
    const renderTooltip = (props: any) => (
        <Tooltip   {...props}>
            Previously marked as unavailable
        </Tooltip>
    );
    return (<>
        {items.map((item, i) => {
            return (<>
                <div style={{ marginTop: "5px" }}>
                    <Checkbox style={{ border: "2px solid #f58a1f" }} value={item.value} onChange={(e) => {
                        if (e.target.value === false) {
                            if (selectedItems?.some(e => parseInt(e.value) === parseInt(item.value))) {
                                let sItems = selectedItems.filter(e => parseInt(e.value) != parseInt(item.value));
                                setSelectedItems(sItems);
                            }
                        } else {

                            selectedItems.push(item);
                            //setSelectedItems((prev) => [...prev, item]);

                        }
                        onchange(selectedItems);
                    }}>

                        <span style={{ fontWeight: "bolder", marginLeft: "5px" }}> {item.label}</span>
                        {
                            item?.isUnAvailable === 1 ?

                                (<OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip}
                                >
                                    <div style={{ cursor: 'pointer' }} className='ms-3 pointer'>
                                        <ExclamationIcon />
                                    </div></OverlayTrigger>) :
                                (null)

                        }
                    </Checkbox >
                </div >
            </>)
        })}

    </>);
};

export default CheckboxGroup;
