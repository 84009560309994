import { Route, Routes } from "react-router-dom";
import InspectorLanding from "../features/scheduler/inspector-landing";
import { HeaderComponent } from "./header.component";
import { InspectorLogin } from "../features/login/login-inspector";
import { AuthorizeComponent } from "./authorize.component";
import InspectorAvailability from "../features/scheduler/inspector-availability";

export function InspectorRoutes() {
    return (
        <AuthorizeComponent role='inspector'>
            <HeaderComponent showMenu={false} disableLogoClick />
            <Routes>
                <Route
                    path="/inspector-login"
                    element={<InspectorLogin />}
                />
                <Route
                    path="/landing-page"
                    element={<InspectorLanding />}
                />
                <Route
                    path="/inspector-availability"
                    element={<InspectorAvailability />}
                />
            </Routes>
        </AuthorizeComponent>)
}