import { StepperProps, TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import * as React from 'react';
import HomeOwnerCard from './components/homeowner-card';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import RequestStatus from './components/claim-request-status';
import Stepper, { Step } from '../../components/stepper';
import ProfileMap from './components/profile-map';
import ClaimHistory from './components/claim-history';
import { ScheduleClaimHandler, ScheduleClaimProfile } from './components/schedule-claim-profile';
import { isEmpty } from 'lodash';
import { StorageKey, WINDOWS_DOORS_API_URL } from '../../constants';
import { useCurrentUser } from '../../hooks/user-current-user.hook';
import { Dialog } from '@progress/kendo-react-dialogs';
import mobileImage from '../../images/mobile-image.png';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { Claim } from '../../models/claim/claim';
import CheckAvailability from './check-availability';
import { useLoadData } from '../../hooks/use-scheduler-load-data';
import { IClaimProfile } from '../../models/scheduler/claim-profile';
import { profile } from 'console';
import { IClaimRequest } from '../../models/scheduler/claim-request';
import { ScheduleHomeowner } from './components/schedule-homeowner';

interface IProfileProps {
}

const Profile: React.FunctionComponent<IProfileProps> = (props) => {
    // Steps array
    const { userName, fullName, userGUID, userID } = useCurrentUser();
    const stepperProps: Step[] = [
        { status: 'stepped', label: 'Received' },
        { status: '', label: 'Awaiting Availability' },
        { status: '', label: 'Pending Schedule' },
        { status: '', label: 'Scheduled' },
        { status: '', label: 'Canceled' }
    ];
    const [stepChange, setStepChange] = useState<Step[]>(stepperProps);
    const loc = useLocation();
    const { state } = loc;

    const [isLoadingRequest, setloadingRequest] = useState<boolean>(false);
    const [confirmMessage, setConfirmMessage] = useState<string>("Availability request has been sent to the Homeowner");
    const [showRequestAvailabilityStatus, setShowRequestAvailabilityStatus] = useState(false);
    const [availability, setChkAvailability] = useState<boolean>(false)
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [showHomeownerScheduler, setShowHomeownerScheduler] = useState(false)


    const { isError: isErrorClaimDetails, isLoading: isLoadingProfileDetail, reload: reloadProfileDetail, result: profileDetailList } = useLoadData<IClaimProfile[]>(`SchedulerAssignments/GetClaimDetailsForProfile?claimID=${state.claimID}`);
    const { isError: isErrorRequestStatus, isLoading: isLoadingRequestStatus, result: requestStatus, reload: reloadRequestStatus } = useLoadData<IClaimRequest[]>(`SchedulerAssignments/GetClaimRequestsForProfile?claimID=${state.claimID}`);

    const profileDetail = profileDetailList?.[0]


    React.useEffect(() => {

        const status = profileDetail?.currentStatus || state?.status

        if (!!status) {
            let index = 0;
            stepperProps[0].status = "stepped";
            if (status.toLowerCase() === "awaiting availability") {
                index = stepperProps.findIndex((p) => p.label == "Awaiting Availability");
            } else if (status.toLowerCase() === "rescheduled" || status.toLowerCase() === "pending") {
                index = stepperProps.findIndex((p) => p.label == "Pending Schedule");
            } else if (status.toLowerCase() === "scheduled") {
                index = stepperProps.findIndex((p) => p.label == "Scheduled");
            } else if (status.toLowerCase() === "cancelled") {
                index = stepperProps.findIndex((p) => p.label == "Canceled");
            }
            for (let i = 1; i <= index; i++) {
                stepperProps[i].status = "stepped";
            }
            stepperProps[index].status = "active";
            setStepChange([...stepperProps]);
        }
    }, [profileDetail]);

    // Send Request
    const onRequestSendHomeOwner = async (itemData: any) => {
        setloadingRequest(true);
        if (!isEmpty(itemData)) {
            const authState = JSON.parse(localStorage.getItem(StorageKey.AuthState));
            const url = `${WINDOWS_DOORS_API_URL}/api/SchedulerAssignments/RequestHomeownerAvailability?schedulerId=${userID}&claimId=${itemData.claimID}&claimNumber=${itemData.claimNo}&schedulerName=${fullName}&homeOwnerName=${itemData.homeOwner}&homeOwnerEmail=${itemData.homeowner_Email}`;
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...(authState && { Authorization: `Bearer ${authState}` }),
                },
            };
            await fetch(url, requestOptions)
                .then(response => {
                    setloadingRequest(false);
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json(); // Adjust based on expected response type
                })
                .then(data => {
                    sendOtp(itemData);
                    setShowRequestAvailabilityStatus(true);
                    setloadingRequest(false);
                })
                .catch(error => {
                    setloadingRequest(false);
                });
        }
    }
    const sendOtp = async (itemData: any) => {
        try {
            // /itemData.homeowner_Email
            if (!isEmpty(itemData?.homeowner_Email)) {
                const emailContent = { email: itemData.homeowner_Email, userType: 'Homeowner' }
                const response = await fetch(`${WINDOWS_DOORS_API_URL}/api/SchedulerAssignments/EmailVerificationAndSendOTP`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(emailContent),
                });
                const data: any = await response.json();

                if (data.success) {
                    console.log("otp sent successfully!");
                } else {
                    console.log(data.message || 'Failed to send OTP');
                }
            } else {
                console.log("Email Id not found!!");
            }
        } catch (ex) {
            console.log('Failed to send OTP');
        }

    }
    const toggleDialog = () => {
        setShowRequestAvailabilityStatus(false);
    };


    const schedulerRef = React.useRef<ScheduleClaimHandler>()

    return (<>
        {isLoadingRequest && (<LoadingIndicator isLoading={true} />)}
        {availability ? <CheckAvailability onClose={() => { setChkAvailability(false); }} claimInfo={state as Scheduler} show={availability} onAddOrDeleteSchedule={() => {
            reloadProfileDetail();
            schedulerRef?.current.reloadCalendar()
        }} />
            :
            null}
        {showRequestAvailabilityStatus &&
            <Dialog width="30%" title={" "} onClose={toggleDialog} style={{ borderRadius: "10px" }} className="delete-dialog">
                <div>
                    <div className="row">
                        <div className="col-3">
                            <img src={mobileImage} />
                        </div>
                        <div className="col-9 justify-content-between">
                            <h5 className="justify-content-center mt-4" style={{ textAlign: "center" }} >{confirmMessage}</h5>
                        </div>
                    </div>
                </div>
            </Dialog>
        }
        <div className="p-4"  >
            <center>
                <div className="w-75">
                    <Stepper steps={stepChange} />
                </div>
            </center>

            <div className="grid-row mt-3">
                <HomeOwnerCard isErrorClaimDetails={isErrorClaimDetails} isLoadingClaimDetails={isLoadingProfileDetail} claimDetails={profileDetail} />
                <ProfileMap claimDetails={profileDetail}></ProfileMap>
                <RequestStatus isErrorRequestStatus={isErrorRequestStatus} isLoadingRequestStatus={isLoadingRequestStatus} requestStatus={requestStatus}
                />
            </div>
            <div>
            </div>


            <div className="card border-0 sw-shadow radius-15 tabsdesign" >
                <div className="tab-btn">
                    <button
                        onClick={() => setShowHomeownerScheduler(true)}
                        className="btn btn-sm btn-outline-purpple me-2">
                        Request Homeowner Availability
                    </button>
                    <button className="btn btn-sm btn-primary" onClick={() => {
                        setChkAvailability(true);
                    }}>
                        Check Inspector Availability
                    </button>
                </div>
                <TabStrip selected={selectedTab} onSelect={(e) => { setSelectedTab(e.selected) }} className='mx-2 mt-3 mb-3 bg-light' style={{ minHeight: "35vh", minWidth: "92vw" }}>
                    <TabStripTab contentClassName="structureTab text-capitalize reportpagetab" title="AVAILABILITY CALENDAR">
                        <ScheduleClaimProfile ref={schedulerRef} claim={state as Scheduler} onAddOrDeleteSchedule={() => {
                            reloadProfileDetail();
                            reloadRequestStatus();
                        }} />
                    </TabStripTab>
                    <TabStripTab contentClassName="structureTab text-capitalize reportpagetab" title="CLAIM HISTORY">
                        <ClaimHistory claimID={state.claimID} />
                    </TabStripTab>
                </TabStrip>
            </div>


        </div >
        {showHomeownerScheduler && <ScheduleHomeowner
            onAddOrDeleteSchedule={() => {
                reloadProfileDetail();
                reloadRequestStatus();
            }}
            show={showHomeownerScheduler}
            claim={state as Scheduler}
            onClose={() => setShowHomeownerScheduler(false)}
            setShowRequestAvailabilityStatus={setShowRequestAvailabilityStatus} />}
    </>);
};
export default Profile;


