import type { AxiosError } from 'axios';
import axios from 'axios';
import { createMutation } from 'react-query-kit';
import { Slot } from '../../types/Slot';
import dayjs from 'dayjs';
import { convertToTimezone } from '../../utils';


type ScheduledSlot = {
    claimID: number;
    claimNo: string;
    dateFrom: string;
    isAvailable: number;
    slotID: number;
    timeSlot: string;
    timeSlot_From: string;
    timeSlot_To: string;
    dateTo: string;
    slotStatus: string
    slotStatusId: number;
};


type Response = {
    slots: { [key: string]: Slot };
    scheduledTime: { [key: string]: boolean }
}


const formatResponse = (response: ScheduledSlot[]): Response => {


    const formattedResponse: Response = response.reduce((obj: Response, slot): Response => {

        const fromDate = dayjs.utc(slot.dateFrom)
        const toDate = dayjs.utc(slot.dateTo)


        obj.slots[fromDate.toISOString()] = {
            id: fromDate.toISOString(),
            title: slot.slotStatus,
            start: fromDate.toDate(),
            end: toDate.toDate(),
            claimNo: slot.claimNo,
            slotId: slot.slotID,
            statusId: slot.slotStatusId
        }



        const formattedStart = convertToTimezone(fromDate.toDate()).format("YYYY-MM-DD hh:mm A")
        const formattedEnd = convertToTimezone(toDate.toDate()).format("YYYY-MM-DD hh:mm A")
        obj.scheduledTime[formattedStart] = true
        obj.scheduledTime[formattedEnd] = true

        return obj
    }, {
        slots: {},
        scheduledTime: {}
    })

    return formattedResponse

}



type Payload = { inspectorId: number; SDate: string; EDate: string };

export const useScheduledSlots = createMutation<Response, Payload, AxiosError>({
    mutationFn: async payload =>
        axios({
            url: `SchedulerAssignments/GetInspSlotsForCalendar?inspectorId=${payload.inspectorId}&SDate=${payload.SDate}&EDate=${payload.EDate}`,
            method: 'GET',
        }).then(response => formatResponse(response.data?.result || [])),
});
