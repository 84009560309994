import dayjs from "dayjs";
import { IClaimHistoryLog } from "../../../models/scheduler/claim-history-log";
import { convertToTimezone, convertToTimezoneWithDateString } from "../../../utils";


type Props = {
    activityDate: string;
    logs: IClaimHistoryLog[];
}

function Item({ activityDate, logs }: Props) {

    return (
        <div className="treelist">
            <div>
                <h4 >
                    {activityDate}
                    {activityDate === convertToTimezone(dayjs().toDate()).format("DD/MM/YYYY") &&
                        " -Today"}
                </h4>

                {logs?.map((log) => {
                    return (
                        <div key={log.logDate}>
                            <div className="historyTab" style={{ boxShadow: "none" }}>
                                <span >
                                    {convertToTimezoneWithDateString(log.logDate).format("hh:mm A")}
                                </span>
                                <div className="historyTab-card">
                                    <div>
                                        <h3
                                            style={{
                                                color: "black",
                                                paddingTop: 0,
                                                fontWeight: 400,
                                                fontSize: "13px",
                                            }}
                                        >
                                            {log.logAction}
                                        </h3>
                                        <div style={{ fontSize: 12, color: '#686666' }} >
                                            {log.claimStatus}
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center ps-3" style={{ borderLeft: '1px solid #ddd' }}  >
                                        <p style={{ color: '#000', margin: '0', fontSize: 13 }} >
                                            {log?.logDescription}
                                        </p>


                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Item;
