import * as React from 'react';
import SelectTime from './select-time-component';
import { useRef } from 'react';
import { string } from 'yup';
import { isEmpty } from 'lodash';

interface ITime {
    fromTime: string, toTime: string
}

interface IManualTimeSelectProps {
    onchange: (timeRange: ITime) => void
}

const ManualTimeSelect: React.FunctionComponent<IManualTimeSelectProps> = (props) => {
    const { onchange } = props;
    const fromTime = useRef<string>("8");
    const toTime = useRef<string>("18");

    React.useEffect(() => {
        formatTimeValue(fromTime.current);
        formatTimeValue(fromTime.current);
    },);

    const formatTimeValue = (hour: any) => {
        if (hour != '') {
            if (hour.split(':').length > 0) {
                let hr = hour.split(':')[0];
                let modifier: string = hour.split(':')[1];

                if (hr === '12') {
                    hr = 0;
                }
                if (!isEmpty(modifier) && modifier.includes("PM")) {
                    hr = parseInt(hr, 10) + 12;
                }
                const period = ':00:00.000Z';
                return `${String(hr).padStart(3, 'T0')}${period}`;
            } else {
                return '';
            }
        }
    };


    return (<>

        <div >

            <div> <SelectTime a='AM' hh='08' mm='00' onChangeTime={(time) => {
                fromTime.current = time;
                onchange({ fromTime: formatTimeValue(fromTime.current), toTime: formatTimeValue(toTime.current) });

            }} />
                <center className='m-2'> To</center>

            </div>
            <div> <SelectTime a='PM' hh='06' mm='00' onChangeTime={(time) => {
                toTime.current = time;
                onchange({ fromTime: formatTimeValue(fromTime.current), toTime: formatTimeValue(toTime.current) });
            }} /></div>

        </div>

    </>);
};

export default ManualTimeSelect;
