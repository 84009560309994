import { useEffect, useState } from 'react';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import './login.style.scss';
import { useStorage } from '../../hooks/use-storage';
import { InspectorStorageKey, StorageKey, WINDOWS_DOORS_API_URL } from '../../constants';
import { UserResponse } from '../../models/user';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from '../../hooks/user-current-user.hook';
import { setAxiosAuthHeaders } from '../../api-client.ts/axios';


interface LoginResponse {
    success: boolean;
    status: number;
    message: string;
    result: string;
}

export const EmailLogin = () => {
    const nav = useNavigate();
    const [currentAuthState, setAuthState] = useStorage(StorageKey.AuthState, null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState('');
    const [error, setError] = useState('');
    const [userGuid, setUserGuid] = useState('');
    const { fullName, userID } = useCurrentUser();
    const sendOtp = async (values: { email: string, userType: string }) => {
        setIsSubmitting(true);
        try {
            values.userType = "Inspector";
            const response = await fetch(`${WINDOWS_DOORS_API_URL}/api/SchedulerAssignments/EmailVerificationAndSendOTP`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });
            const data: LoginResponse = await response.json();

            if (data.success) {
                setUserGuid(data?.result);
                setShowOtpInput(true);

            } else {
                setError(data.message || 'Failed to send OTP');
            }
        } catch (ex) {
            setError('Failed to send OTP');
        } finally {
            setIsSubmitting(false);
        }
    };

    const verifyOtp = async () => {
        setIsSubmitting(true);
        try {
            //03108282-CF69-43CA-A0AB-2D015037E597
            const response = await fetch(`${WINDOWS_DOORS_API_URL}/api/SchedulerAssignments/VerifyOTP?OTP=${otp}&UserGuid=${userGuid}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data: UserResponse = await response.json();
            if (data.isSuccess) {
                //data.schUsers.userId = userID;
                setAuthState(data.token);
                setAxiosAuthHeaders()

                nav('/inspector/landing-page', { state: { ...data } })
            } else {
                setOtpError('Invalid OTP');
            }
        } catch (ex) {
            setOtpError('Failed to verify OTP');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleSubmit = async (values: { email: string, userType: string }) => {
        if (showOtpInput) {
            await verifyOtp();
        } else {
            await sendOtp(values);
        }
    };

    const initialLoginData = {
        email: '',
        userType: ''
    };

    const signupSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
    });

    return (
        <>
            {error && <div className="alert alert-danger">{error}</div>}
            {otpError && <div className="alert alert-danger">{otpError}</div>}
            <LoadingIndicator isLoading={isSubmitting} />
            <Formik
                initialValues={initialLoginData}
                validationSchema={signupSchema}
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form className="form-content">
                        <div className="row mb-3 align-items-center">
                            <div className="col">
                                <label htmlFor="email">Email</label>
                                <Field
                                    className="form-control form-control-md"
                                    name="email"
                                    placeholder="Enter email address"
                                    type="email"
                                />
                                <div className="mt-4">
                                    <small className="errorMsg">
                                        <ErrorMessage name="email" />
                                    </small>
                                </div>
                            </div>
                        </div>
                        {showOtpInput && (
                            <div className="row mb-3 align-items-center">
                                <div className="col">
                                    <Field
                                        className="form-control form-control-md"
                                        name="otp"
                                        placeholder="Enter One Time Code"
                                        value={otp}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOtp(e.target.value)}
                                    />
                                </div>
                                <p className="mt-2" style={{ fontSize: "12px", textAlign: "center" }}>(This One Time Code will be valid for the next 5 mins.)</p>
                            </div>
                        )}

                        <button
                            id="btnSignIn"
                            className="btn btn-normal btn-block w-100 mt-2"
                            type="submit"
                            disabled={isSubmitting}
                        >
                            {showOtpInput ? 'Verify One Time Code' : 'Send One Time Code'}
                        </button>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default EmailLogin;
