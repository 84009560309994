
export const formatPhoneNumber = (phoneNumber: string) => {
    // Remove any non-numeric characters
    const numericPhoneNumber = phoneNumber?.replace(/\D/g, "");

    // Apply formatting based on the length of the numeric string

    if (numericPhoneNumber?.length === 10) {
        return `(${numericPhoneNumber?.slice(0, 3)}) ${numericPhoneNumber?.slice(
            3,
            6,
        )}-${numericPhoneNumber?.slice(6)}`;
    }

    return numericPhoneNumber;
};
