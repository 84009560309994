import dayjs from "dayjs";
import { TIME_ZONE } from "../constants";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export const convertToTimezone = (date: Date, timezone?: string) =>
    dayjs.utc(date).tz(timezone || TIME_ZONE)

export const convertToTimezoneWithDateString = (date: string, timezone?: string) =>
    dayjs.utc(date).tz(timezone || TIME_ZONE)

export const parseAsDateFromString = (dateString: string, timeString: string) => {
    const dateTimeString = `${dateString}${timeString}`;
    const dateTimeCST = dayjs.tz(dateTimeString, TIME_ZONE);
    return dateTimeCST.utc().toISOString();
}