import * as React from 'react';
import { useLoadData } from '../../hooks/use-scheduler-load-data';
import { IInspectorAvailability } from '../../models/scheduler/inspector-availability';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { parseDate, formatDate } from '@progress/kendo-intl';
import DatePicker from 'react-datepicker';
import { useEffect, useRef, useState } from 'react';
import CloseIcon from '../../images/close-icon';
import EditIcon from '../../images/edit';
import SingleSelectDropdown from '../../components/single-select.component';
import SingleDropdown from '../../components/single-select-dropdown';
import { Image } from 'react-bootstrap';
import AddAvailability from './components/add-inspector-availability';
import { useLocation, useNavigate } from 'react-router-dom';
import { ISaveTimeSlot } from '../../models/scheduler/save-time-slot';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ISlot } from './components/timeslot-component';
import axios from 'axios';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { convertToTimezone, convertToTimezoneWithDateString } from '../../utils';
import LocationIcon from '../../images/location';

interface IInspectorAvailabilityProps { }

const InspectorAvailability: React.FunctionComponent<IInspectorAvailabilityProps> = (props) => {
    const nav = useNavigate();
    const loc = useLocation();
    const { state } = loc;
    const { schUsers } = state;
    const { userGuid, userId, userName } = schUsers;
    // let userId = 30;
    // let userName = "pragupathi"
    // let userGuid = "";
    const [del, setDel] = useState<{ isDel: boolean, deleteData?: IInspectorAvailability }>({ deleteData: undefined, isDel: false })

    const [fromDate, setFromDate] = useState<Date>(new Date(dayjs().add(-3, "months").format("MM/DD/YYYY")));
    const [toDate, setToDate] = useState<Date>(new Date(dayjs().add(3, "months").format("MM/DD/YYYY")));
    const [isAvailable, setIsAvailable] = useState<number>(-1);
    const [isAdd, setIsAdd] = useState<boolean>(false);
    const [dateValidation, setDateValidation] = useState(false);
    const unAvailableDaySlots = useRef<ISlot[]>([])
    const unAvailableTimeSlots = useRef<ISlot[]>([])
    const toggleDialogValidation = () => {
        setDateValidation(false);
    };

    const { isError, isLoading, reload, result } = useLoadData<IInspectorAvailability[]>(
        `SchedulerAssignments/GetInspectorAvailability?SDate=${encodeURIComponent(formatDate(fromDate, 'MM/dd/yyy'))}&FDate=${encodeURIComponent(formatDate(toDate, 'MM/dd/yyy'))}&InspectorID=${schUsers.userId}`,
    );
    console.log(schUsers)
    function addMonths(date: Date, months: number) {
        var month = (date.getMonth() + months) % 12;
        //create a new Date object that gets the last day of the desired month
        var last = new Date(date.getFullYear(), month + 1, 0);

        //compare dates and set appropriately
        if (date.getDate() <= last.getDate()) {
            date.setMonth(month);
        } else {
            date.setMonth(month, last.getDate());
        }

        return date;
    }

    const initialSlotData = React.useRef<ISaveTimeSlot>({
        claimNo: '', dateSlots: [],
        isAvailable: true,
        schID: 0,
        sDate: '',
        timeSlot: '',
        userGUID: userGuid,
        claimId: 0,
        userId: userId,
        inspectorId: userId,
    });


    const [inspectorAvailabilities, setInspectorAvailabilities] = useState<
        IInspectorAvailability[]
    >([]);

    useEffect(() => {
        if (result) {
            let data = result.filter((e) => e.isAvailable === isAvailable && (e.claimNo === undefined || e.claimNo === ""));
            if (isAvailable > -1) {
                setInspectorAvailabilities([...data]);
            } else {
                setInspectorAvailabilities([...result]);
            }
        }

    }, [isAvailable, result]);


    const handleDelete = (data: IInspectorAvailability) => {
        setDel({ isDel: true, deleteData: data })
    }
    return (
        <>
            {isLoading && <LoadingIndicator isLoading />}
            {isError && (
                <>
                    <div>Error Loading Availability Details</div>
                </>
            )}

            {!isLoading && result && (
                <div className="">
                    <div className="d-flex justify-content-between align-items-center ps-4 pe-4 ps-lg-5 ps-lg-3 pe-lg-5 pe-lg-3 pb-0 pt-3 pb-3">
                        <div>
                            <button
                                className="backbtn"
                                onClick={() => {
                                    nav('/inspector/landing-page', { state: state });
                                }}
                            >
                                Back
                            </button>
                        </div>
                        <div style={{ fontWeight: 'bold' }}>
                            <div>{'My Availability'.toUpperCase()}</div>
                        </div>

                        <div>
                            <button
                                className="btn btn-primary btn-md text-capitalize fw-normal sw-shadow"
                                onClick={() => {
                                    let days = result.map(e => e.dateFrom);
                                    let dayslots: ISlot[] = days.map(e => { return { label: formatDate(parseDate(e), "EE,MM/dd/yyyy"), value: formatDate(parseDate(e), "MM/dd/yyyy") } })
                                    unAvailableDaySlots.current = dayslots;
                                    let time: ISlot[] = result.map(e => { return { label: e.timeSlot, value: e.timeSlot } })
                                    unAvailableTimeSlots.current = time;

                                    setIsAdd(true);
                                }}
                            >
                                Add Availability
                            </button>
                        </div>
                    </div>
                    {dateValidation && (
                        <Dialog
                            title={'Date Validation'}
                            onClose={toggleDialogValidation}
                            style={{ borderRadius: '10px' }}
                            className="delete-dialog"
                        >
                            <div>
                                <div className="row">
                                    <div className="d-flex justify-content-between align-items-center mt-2 mb-2'">
                                        <p style={{ fontSize: '16px', fontWeight: 'bold' }}>
                                            From Date must be greater than to To Date
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    )}
                    <div
                        className="card p-4 p-4  p-lg-5 pt-lg-3 border-0 radius-page-15 sw-shadow "
                        style={{ minHeight: 'Calc(100vh - 170px)' }}
                    >
                        <div className=" d-flex justify-content-end mb-3 mt-3 ">
                            <div className="d-flex align-items-center ">
                                <div>
                                    <DatePicker
                                        openToDate={fromDate}
                                        className="form-control calendarBg"
                                        value={formatDate(fromDate, 'MM/dd/yyyy')}
                                        placeholderText="To"
                                        dateFormat={'MM/dd/yyyy'}
                                        onChange={(date) => {
                                            var strtDt = new Date(date);
                                            var endDt = new Date(toDate);
                                            if (strtDt > endDt) {
                                                setDateValidation(true);
                                                setFromDate(endDt);
                                            } else {
                                                setFromDate(date);
                                            }
                                            setDateValidation(false);
                                        }}
                                    />
                                </div>
                                <div className="padding-fix-res">
                                    <span> to </span>
                                </div>
                                <div className="pe-3">
                                    <DatePicker
                                        openToDate={toDate}
                                        className="form-control calendarBg"
                                        value={formatDate(toDate, 'MM/dd/yyyy')}
                                        placeholderText="To"
                                        dateFormat={'MM/dd/yyyy'}
                                        onChange={(date) => {
                                            setToDate(date);
                                        }}
                                    />
                                </div>
                                <div>
                                    <SingleDropdown
                                        id="ddlAvailability"
                                        isDef={{ text: 'All', value: '-1' }}
                                        dataitems={[
                                            { text: 'Available', value: '1' },
                                            { text: 'Unavailable', value: '0' },
                                        ]}
                                        onchange={(value: string) => {
                                            setIsAvailable(parseInt(value));
                                        }}
                                    />
                                </div>
                            </div>
                            {isAdd && (
                                <div>
                                    <AddAvailability
                                        onSuccess={() => {
                                            reload();
                                        }}
                                        closeDialog={() => {
                                            setIsAdd(false);
                                        }}
                                        data={initialSlotData.current}
                                        unAvailableDaySlots={unAvailableDaySlots.current}
                                        unAvailableTimeSlots={unAvailableTimeSlots.current}
                                    />
                                </div>
                            )}
                        </div>
                        <div className='responsivfix-3colm'>
                            {inspectorAvailabilities?.map((e) => (
                                <>
                                    {/*Location with Claim No*/}
                                    {e?.location && e.location !== '' && (
                                        <div style={{ backgroundColor: "#eeeeee" }} className="shadow-md rounded-3 p-3 m-1">

                                            <div className="inner-available-grid-4col">
                                                <div className='subgrid'>{`${convertToTimezoneWithDateString(e?.dateFrom).format("MM/DD/YYYY hh:mmA")}-${convertToTimezoneWithDateString(e?.dateTo).format("hh:mmA")}`}</div>
                                                {/* location Icon */}
                                                <div>
                                                    <h5 className="mb-0">
                                                        <LocationIcon />
                                                        Location
                                                    </h5>
                                                </div>
                                                {/* claimno delete icon */}
                                                <div style={{ color: "#f58a1f", display: "inline-block" }}>{"#" + e?.claimNo}</div>
                                                <div onClick={() => handleDelete(e)} style={{ cursor: 'pointer' }}>
                                                    <CloseIcon />
                                                </div>

                                            </div>


                                        </div>
                                    )}
                                    {e?.claimNo && e.claimNo !== '' && isEmpty(e.location) && (
                                        <div style={{ backgroundColor: "#D5FFC4" }} className="shadow-md rounded-3 p-3 m-1">

                                            <div className="inner-available-grid-4col">
                                                <div className='subgrid' >{`${convertToTimezoneWithDateString(e?.dateFrom).format("MM/DD/YYYY hh:mmA")}-${convertToTimezoneWithDateString(e?.dateTo).format("hh:mmA")}`}</div>
                                                <div className="text-success"><h5>Available</h5></div>
                                                {/* claimno delete icon */}
                                                <div style={{ color: "#f58a1f", display: "inline-block" }}>{"#" + e?.claimNo}</div>

                                                <div onClick={() => handleDelete(e)} className="me-2" style={{ cursor: 'pointer' }}>
                                                    <CloseIcon />

                                                </div>

                                            </div>


                                        </div>
                                    )}
                                    {/* Available */}
                                    {e.isAvailable === 1 && e.claimNo === undefined && (
                                        <div style={{ backgroundColor: "#D5FFC4" }} className="shadow-md rounded-3 p-3 m-1 ">

                                            <div className='inner-available-grid'>
                                                <div className="subgrid">{`${convertToTimezoneWithDateString(e?.dateFrom).format("MM/DD/YYYY hh:mmA")}-${convertToTimezoneWithDateString(e?.dateTo).format("hh:mmA")}`}</div>
                                                <div className="text-success"><h5>Available</h5></div>
                                                {/* claimno delete icon */}
                                                <div onClick={() => handleDelete(e)} className="me-2" style={{ cursor: 'pointer' }}>
                                                    <CloseIcon />
                                                </div>
                                            </div>


                                        </div >
                                    )}
                                    {/* unavailable */}
                                    {e.isAvailable === 0 && (
                                        <div className="shadow-md rounded-3 p-3 m-1 bg-danger-subtle">

                                            <div className="inner-available-grid">
                                                <div className=" subgrid">{`${convertToTimezoneWithDateString(e?.dateFrom).format("MM/DD/YYYY hh:mmA")}-${convertToTimezoneWithDateString(e?.dateTo).format("hh:mmA")}`}</div>
                                                {/* location Icon */}
                                                <div className="text-danger"><h5>Unavailable</h5></div>
                                                {/* claimno delete icon */}
                                                <div style={{ cursor: "pointer" }} onClick={() => handleDelete(e)}>
                                                    <CloseIcon />
                                                </div>
                                            </div>


                                        </div>
                                    )}
                                </>
                            ))}
                        </div>
                    </div>
                </div >
            )}
            {
                del.isDel && (<Dialog title='Delete Slot' width={"30%"} onClose={() => { setDel({ isDel: false, deleteData: undefined }) }} key={1}>
                    <center>
                        <h5>Are you sure you want to delete ?</h5>
                    </center>
                    <DialogActionsBar>
                        <div className='row'>
                            <div className='d-flex justify-content-between' >
                                <button className='btn btn-sm btn-secondary' onClick={() => {
                                    setDel({ isDel: false, deleteData: undefined })
                                }}>No</button>
                                <button className='btn btn-primary btn-sm' onClick={async () => {
                                    const response = await axios.post(`SchedulerAssignments/DeleteInspSlotsForCalendar?inspectorId=${userId}&slotId=${del.deleteData.slotId}&userId=${userId}&userName=${userName}&claimNumber=${del.deleteData.claimNo}`);
                                    if (response.data.result === -1) {
                                        setDel({ isDel: false, deleteData: undefined });
                                        reload();
                                    }
                                }}>Yes</button>

                            </div>
                        </div>
                    </DialogActionsBar>

                </Dialog>)
            }

        </>
    );
};

export default InspectorAvailability;
