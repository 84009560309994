
import * as React from 'react';

import DatePicker from 'react-datepicker';
import { useEffect, useRef, useState } from 'react';
import { formatDate, parseDate } from '@progress/kendo-intl';
import { Grid, GridColumn, GridColumnMenuFilter, GridColumnMenuProps, GridDataStateChangeEvent, GridExpandChangeEvent, GridNoRecords, GridPageChangeEvent } from '@progress/kendo-react-grid';

import { useLoadData } from '../../hooks/use-scheduler-load-data';
import { DataResult, process, SortDescriptor, State } from '@progress/kendo-data-query';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { Link, useNavigate } from 'react-router-dom';
import DashboardCount from './components/dashboard-count-component';
import mobileImage from '../../images/mobile-image.png';


import CheckAvailability from './check-availability';
import { StorageKey, WINDOWS_DOORS_API_URL } from '../../constants';
import { isEmpty } from 'lodash';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useCurrentUser } from '../../hooks/user-current-user.hook';
import dayjs from 'dayjs';
import {
    setExpandedState,
    setGroupIds,
} from "@progress/kendo-react-data-tools";
import { ScheduleHomeowner } from './components/schedule-homeowner';
import { formatPhoneNumber } from './utils';
interface IClaimInfo {

}
interface ISchedulerDashboardProps {
}

const SchedulerDashboard: React.FunctionComponent<ISchedulerDashboardProps> = (props) => {
    ///state variables
    const initialDataState: State = {
        take: 10,
        skip: 0,
        group: [],
    };


    const [showHomeownerScheduler, setShowHomeownerScheduler] = useState(false)
    const [fromDate, setFromDate] = useState<Date>(new Date(dayjs().add(-6, "months").format("MM/DD/YYYY")))
    const [toDate, setToDate] = useState<Date>(new Date())
    const fromDateRef = useRef<Date>(new Date(dayjs().add(-6, "months").format("MM/DD/YYYY")));
    const toDateRef = useRef<Date>(new Date());
    const chkRef = useRef<Scheduler>(null);
    const [gridData, setGridData] = useState<DataResult>({ data: [], total: 0 });
    const initialSort: Array<SortDescriptor> = [
        { field: "claimNo", dir: "asc" },
    ];
    const [resultData, setResultData] = useState<DataResult>();

    const [dataState, setDataState] = useState<State>(initialDataState);
    const [sort, setSort] = React.useState(initialSort);
    const [availability, setChkAvailability] = useState<boolean>(false)
    ///final url
    const url = `SchedulerAssignments/GetSchedulerAssignments?SDate=${encodeURIComponent(formatDate(fromDateRef.current, "MM/dd/yyyy"))}&FDate=${encodeURIComponent(formatDate(toDateRef.current, "MM/dd/yyyy"))}`;

    ///data fetch hook useloaddata
    const { isLoading, isError, reload: reloadClaimScheduler, result } = useLoadData<Scheduler[]>(url);
    const [isLoadingRequest, setloadingRequest] = useState(false);
    const [showScheduler, setShowScheduler] = useState(false)
    const [showRequestAvailabilityStatus, setShowRequestAvailabilityStatus] = useState(false);
    const nav = useNavigate();
    const [cancelFilter, setCancelFilter] = useState(false);
    const [lastFilterStatus, setLastFilterStatus] = useState(String);
    const { userName, fullName, userGUID, userID } = useCurrentUser();
    const [collapsedState, setCollapsedState] = React.useState([]);
    const filteredDataRef = useRef<Scheduler[]>([]);
    //Grouping Data
    const initialGroup = [
        {
            field: "claimNo",
        },
    ];

    const processWithGroups = (data: Scheduler[], dataState: State) => {
        const groups = dataState.group;
        dataState.group = groups;
        const newDataState = process(data, dataState);
        setGroupIds({ data: newDataState.data, group: dataState.group });
        return newDataState;
    };

    ///init method
    useEffect(() => {
        if (result !== undefined) {
            result.forEach(e => {
                e.dateofLoss = formatDate(parseDate(e.dateofLoss), 'MM/dd/yyyy')
            })
            const DataRes = cancelFilter === true ? process(filteredDataRef.current, dataState) : process(result, dataState);
            setGridData(DataRes);
            cancelFilter === true ? setResultData(processWithGroups(filteredDataRef.current, dataState)) : setResultData(processWithGroups(result, dataState));
        }
    }, [result, dataState]);
    useEffect(() => {
        if (cancelFilter === true) {
            setDataState(initialDataState)
        }
    }, [filteredDataRef.current])

    const expandChange = (event: GridExpandChangeEvent) => {
        const item = event.dataItem;

        if (item.groupId) {
            const newCollapsedIds = !event.value
                ? [...collapsedState, item.groupId]
                : collapsedState.filter((groupId) => groupId !== item.groupId);
            setCollapsedState(newCollapsedIds);
        }
    };

    let newData: any
    if (resultData) {
        newData = setExpandedState({
            data: resultData.data,
            collapsedIds: collapsedState,
        });
    }

    const onExpandChange = React.useCallback(
        (event: any) => {
            const item = event.dataItem;
            if (item.groupId) {
                const newCollapsedIds = !event.value
                    ? [...collapsedState, item.groupId]
                    : collapsedState.filter((groupId) => groupId !== item.groupId);
                setCollapsedState(newCollapsedIds);
            }
        },
        [collapsedState]
    );
    //End Grouping

    const onStatusFilterToggle = (status: string) => {
        if (!cancelFilter || lastFilterStatus !== status) {
            const filteredData = status?.toLowerCase() === "pending" ?
                result?.filter((p) => p.status?.toLowerCase().includes("awaiting availability") ||
                    p.status?.toLowerCase().includes("rescheduled"))
                :
                result?.filter((p) => p.status?.toLowerCase().includes(status?.toLowerCase()));

            setGridData(process(filteredData, dataState));
            setResultData(process(filteredData, dataState));
            setCancelFilter(true);
            filteredDataRef.current = filteredData;
        } else {
            setGridData(process(result, dataState));
            setResultData(process(result, dataState));
            setCancelFilter(false);
            filteredDataRef.current = [];
        }
        setLastFilterStatus(status);
    };


    const onDateFilterToggle = (status: string) => {

        const current = new Date();
        const tomorrow = current.setDate(current.getDate() + 1);
        if (!cancelFilter || lastFilterStatus !== status) {
            const filteredData = status?.toLowerCase() === "today" ?
                result.filter((p) => formatDate(new Date(p?.scheduleOn), "MM/dd/yyyy") === formatDate(new Date(), "MM/dd/yyyy") && p?.status?.toLowerCase() === "scheduled") :
                status?.toLowerCase() === "tomorrow" ? result.filter((p) => (formatDate(new Date(p?.scheduleOn), "MM/dd/yyyy") === formatDate(new Date(tomorrow), "MM/dd/yyyy")) && p?.status?.toLowerCase() === "scheduled") :
                    result.filter((p) => dayjs(p?.scheduleOn) >= dayjs().startOf("week") && dayjs(p?.scheduleOn) < dayjs().endOf("week") && p?.status?.toLowerCase() === ("scheduled"));

            setGridData(process(filteredData, dataState));
            setResultData(process(filteredData, dataState));
            setCancelFilter(true);
            filteredDataRef.current = filteredData;
        } else {
            setGridData(process(result, dataState));
            setResultData(process(result, dataState));
            setCancelFilter(false);
            filteredDataRef.current = [];

        }
        setLastFilterStatus(status);
    };

    const onSearch = (e: any) => {
        if (e.target.value.length > 0) {
            const searchKey: string = e.target.value;
            const filteredData = result.filter(
                (p) =>
                    p.claimNo?.toLowerCase().includes(searchKey?.toLowerCase()) ||
                    p.policyNo?.toLowerCase().includes(searchKey?.toLowerCase()) ||
                    p.keyStoneNo?.toLowerCase().includes(searchKey?.toLowerCase()) ||
                    p.address?.toLowerCase().includes(searchKey?.toLowerCase()) ||
                    p.homeOwner?.toLowerCase().includes(searchKey?.toLowerCase()) ||
                    p.homeowneravailability?.toLowerCase().includes(searchKey?.toLowerCase()) ||
                    p.homeOwner_Phone?.toLowerCase().includes(searchKey.toLowerCase()) ||
                    p.inspector?.toLowerCase().includes(searchKey?.toLowerCase()) ||
                    p.status?.toLowerCase().includes(searchKey?.toLowerCase())
            );
            setGridData(process(filteredData, dataState));
            setResultData(process(filteredData, dataState));
            filteredDataRef.current = filteredData;
        } else {
            setResultData(process(result, dataState));
            setGridData(process(result, dataState));
            filteredDataRef.current = [];
        }
    };

    const ColumnMenu = (props: GridColumnMenuProps) => {
        return (
            <div>
                <GridColumnMenuFilter {...props} expanded={true} />
            </div>
        );
    };
    const dataStateChange = (event: GridDataStateChangeEvent) => {
        const newDataState = process(result, dataState);
        setResultData(newDataState);
        setDataState(event.dataState);
    };
    const handleDataStateChange = (e: GridPageChangeEvent) => {
        const newDataState = { skip: e.page.skip, take: e.page.take };
        setDataState(newDataState);


    };



    const [events, setEvents] = useState([
        { id: 1, start: new Date(), end: new Date(), title: 'Meeting', isEditable: true },
        { id: 2, start: new Date(), end: new Date(), title: 'Non-Editable Event', isEditable: false },
    ]);

    const onRequestSendHomeOwner = async (itemData: any) => {
        setloadingRequest(true);
        if (!isEmpty(itemData)) {
            const authState = JSON.parse(localStorage.getItem(StorageKey.AuthState));
            const url = `${WINDOWS_DOORS_API_URL}/api/SchedulerAssignments/RequestHomeownerAvailability?schedulerId=${userID}&claimId=${itemData.claimID}&claimNumber=${itemData.claimNo}&schedulerName=${fullName}&homeOwnerName=${itemData.homeOwner}&homeOwnerEmail=${itemData.homeowner_Email}`;
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...(authState && { Authorization: `Bearer ${authState}` }),
                },
            };
            await fetch(url, requestOptions)
                .then(response => {
                    setloadingRequest(false);
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json(); // Adjust based on expected response type
                })
                .then(data => {
                    sendOtp(itemData);
                    setShowRequestAvailabilityStatus(true);
                    setloadingRequest(false);
                })
                .catch(error => {
                    setloadingRequest(false);
                });
        }
    }
    const sendOtp = async (itemData: any) => {
        try {
            // /itemData.homeowner_Email
            if (!isEmpty(itemData?.homeowner_Email)) {
                const emailContent = { email: itemData.homeowner_Email, userType: 'Homeowner' }
                const response = await fetch(`${WINDOWS_DOORS_API_URL}/api/SchedulerAssignments/EmailVerificationAndSendOTP`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(emailContent),
                });
                const data: any = await response.json();

                if (data.success) {
                    console.log("otp sent successfully!");
                } else {
                    console.log(data.message || 'Failed to send OTP');
                }
            } else {
                console.log("Email Id not found!!");
            }
        } catch (ex) {
            console.log('Failed to send OTP');
        }

    }
    const toggleDialog = () => {
        setShowRequestAvailabilityStatus(false);
    };



    return (<>
        <div className='m-lg-5 mt-lg-3 m-4'>
            <div className="d-flex justify-content-between pt-3 pb-3">
                <div>
                    <input
                        type="text"
                        className="form-control ms-auto search-right-align searchBg"
                        onChange={onSearch}
                    />
                </div>
                <div className='d-flex' >
                    <div className='me-2'>
                        <DatePicker
                            openToDate={fromDate}
                            className="form-control ms-auto search-right-align calendarBg"
                            value={formatDate(fromDate, "MM/dd/yyyy")} placeholderText='To' dateFormat={"MM/dd/yyyy"} onChange={(date) => {
                                setFromDate(date);
                            }} />  </div>
                    <div className='me-2'>
                        <DatePicker
                            openToDate={toDate}
                            className="form-control ms-auto search-right-align calendarBg"
                            value={formatDate(toDate, "MM/dd/yyyy")} placeholderText='To' dateFormat={"MM/dd/yyyy"} onChange={(date) => {
                                setToDate(date);
                            }} />  </div>
                    <div>
                        <button className="btn-primary btn search-btn" onClick={() => {
                            fromDateRef.current = fromDate
                            toDateRef.current = toDate;
                            reloadClaimScheduler();
                        }} type="submit" >
                            <svg width="17" height="17" viewBox="0 0 17.5 17.5">
                                <path id="Path_magnify" data-name="Path / magnify" d="M9.5,3a6.5,6.5,0,0,1,4.94,10.73l.27.27h.79l5,5L19,20.5l-5-5v-.79l-.27-.27A6.5,6.5,0,1,1,9.5,3m0,2A4.5,4.5,0,1,0,14,9.5,4.481,4.481,0,0,0,9.5,5Z" transform="translate(-3 -3)" fill="#fff" />
                            </svg>

                        </button>
                    </div>
                </div>
            </div>
            <DashboardCount onCancelFilterToggle={onStatusFilterToggle} onCancelDateToggle={onDateFilterToggle} />
            {availability ? <CheckAvailability onClose={() => { setChkAvailability(false); }} claimInfo={chkRef.current} show={availability} onAddOrDeleteSchedule={reloadClaimScheduler} />
                :
                null}
            {showRequestAvailabilityStatus &&
                <Dialog width="30%" title={" "} onClose={toggleDialog} style={{ borderRadius: "10px" }} className="delete-dialog">
                    <div>
                        <div className="row">
                            <div className="col-3">
                                <img src={mobileImage} />
                            </div>
                            <div className="col-9 justify-content-between">
                                <h5 className="justify-content-center mt-4" style={{ textAlign: "center" }} >Availability request has been
                                    sent to the Home owner</h5>
                            </div>
                        </div>
                    </div>
                </Dialog>
            }
            <div className='card p-4 border-0 sw-shadow radius-15 border-0 '>
                {isError && (<div>Error</div>)}
                {isLoading && (<LoadingIndicator isLoading />)}
                {isLoadingRequest && (<LoadingIndicator isLoading />)}
                {!isLoading && (<Grid
                    // data={orderBy(gridData.data, sort)}
                    data={newData}
                    pageable={{ pageSizes: [10, 20, 50, 100] }}
                    skip={dataState.skip}
                    take={dataState.take}
                    total={gridData.total}
                    groupable={true}
                    onExpandChange={expandChange}
                    sortable={true}
                    onDataStateChange={dataStateChange}
                    {...dataState}
                    onPageChange={(e) => handleDataStateChange(e)}
                    navigatable={false}
                >
                    <GridColumn width={"65px"} field="aging_Colour" title="AGING"
                        cell={(props) => (
                            <td style={{ textAlign: 'center' }}>
                                {props.dataItem?.aging_Colour?.toLowerCase() === 'yellow' && (
                                    <div style={{ width: "15px", height: "15px" }} className="scheduler-aging-yellow">
                                    </div>)
                                }
                                {props.dataItem?.aging_Colour?.toLowerCase() === "red" &&
                                    (<div style={{ width: "15px", height: "15px" }} className="scheduler-aging-red">
                                    </div>)
                                }
                            </td>
                        )}

                    />
                    <GridColumn
                        width={"200px"}
                        field="claimNo"

                        columnMenu={ColumnMenu}
                        title="CLAIM#"
                        cell={(props) => (
                            <td style={{ textAlign: 'start' }}>
                                <div>
                                    {
                                        <a className='text-decoration-underline' style={{ cursor: "pointer" }} onClick={(e) => {
                                            nav("/profile", { state: { ...props.dataItem } })
                                        }}>{props.dataItem.claimNo}</a>
                                    }
                                </div>
                            </td>
                        )}

                    />
                    <GridColumn
                        width={"160px"}
                        field="policyNo"
                        columnMenu={ColumnMenu}
                        title="POLICY#" />
                    <GridColumn field="keyStoneNo" title="KEYSTONE#" width={"160px"} columnMenu={ColumnMenu} />
                    <GridColumn field="homeOwner" title="HOME OWNER" width={"200px"} columnMenu={ColumnMenu} />
                    <GridColumn field="homeOwner_Phone" columnMenu={ColumnMenu} width={"180px"} title="HOME OWNER PHONE" cell={(props) => {

                        return <td className={props.className}>{!!props.dataItem?.[props.field] && formatPhoneNumber(props.dataItem[props.field])}</td>
                    }} />
                    <GridColumn columnMenu={ColumnMenu} width={"200px"} className='text-nowrap' field="address" title="ADDRESS" />
                    <GridColumn className='text-nowrap' width={"180px"} columnMenu={ColumnMenu} title="SCHEDULED ON"
                        cell={(props) => (
                            <td style={{ textAlign: 'start' }}>
                                <div>
                                    {
                                        formatDate(!isEmpty(props?.dataItem?.scheduleOn) && new Date(props?.dataItem?.scheduleOn), "MM/dd/yyyy")
                                    }
                                </div>
                            </td>
                        )}

                    />
                    <GridColumn width={"200px"}
                        columnMenu={ColumnMenu}
                        field="inspector"
                        title="INSPECTOR"
                        cell={(props) => (
                            <td style={{ textAlign: 'start' }}>
                                <div>
                                    {props.dataItem.status === 'Scheduled' ? <Link to={"#"} style={{ cursor: 'pointer', }}>{props.dataItem.inspector}</Link> : <button onClick={() => {
                                        setChkAvailability(true);
                                        chkRef.current = props.dataItem;
                                    }} className='btn btn-outline-primary ms-auto btn-sm text-capitalize'>Check Availability</button>
                                    }
                                </div>
                            </td>
                        )}
                    />
                    <GridColumn width={"200px"}
                        columnMenu={ColumnMenu}
                        field="homeowneravailability"
                        title="HOMEOWNER AVAILABILITY"
                        cell={(props) => (
                            <td style={{ textAlign: 'start' }}>
                                <div>
                                    {
                                        props.dataItem?.homeowneravailability?.toLowerCase() !== 'request' ? <Link to={"#"} style={{ cursor: 'pointer', }}>{props.dataItem.homeowneravailability}</Link> : <button onClick={() => {
                                            chkRef.current = props.dataItem;
                                            setShowHomeownerScheduler(true)
                                        }} className='btn btn-outline-primary ms-auto btn-sm text-capitalize'>Request</button>
                                    }
                                </div>
                            </td>
                        )}
                    />
                    <GridColumn columnMenu={ColumnMenu} width={"200px"} field="status" title="STATUS" />
                    <GridColumn className='text-nowrap' width={"180px"} columnMenu={ColumnMenu} title="CREATED ON"
                        cell={(props) => (
                            <td style={{ textAlign: 'start' }}>
                                <div>
                                    {
                                        formatDate(!isEmpty(props?.dataItem?.createdOn) && new Date(props?.dataItem?.createdOn), "MM/dd/yyyy")
                                    }
                                </div>
                            </td>
                        )}

                    />
                    <GridNoRecords>
                        <div style={{ width: "100%", height: "500px" }}>
                            <center>No data found</center>
                        </div>
                    </GridNoRecords>
                </Grid>)}
            </div>

            <div className="d-flex justify-content-end mt-3 ">
                <button style={{ display: "none" }} className="btn-primary btn-sm me-1 btn" onClick={() => {
                    nav('/homeowner/login')
                }}>
                    Go To Home Owner Dashboard
                </button>

                <button style={{ display: "none" }} className="btn-primary btn-sm me-1 btn" onClick={() => {
                    nav('/inspector/inspector-availability')
                }}>
                    Go To Inspector Availability
                </button>
                <button style={{ display: "none" }} className="btn-primary btn-sm me-1 btn" onClick={() => setShowScheduler(true)}>
                    Schedule
                </button>
            </div>

        </div>
        {showHomeownerScheduler && <ScheduleHomeowner
            onAddOrDeleteSchedule={reloadClaimScheduler}
            show={showHomeownerScheduler}
            claim={chkRef.current}
            onClose={() => setShowHomeownerScheduler(false)}
            setShowRequestAvailabilityStatus={setShowRequestAvailabilityStatus} />}
    </>);




};

export default SchedulerDashboard;
