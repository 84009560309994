import type { AxiosError } from 'axios';
import axios from 'axios';
import { createMutation } from 'react-query-kit';
import { Slot } from '../../types/Slot';
import dayjs from 'dayjs';
import { convertToTimezone } from '../../utils';
import { SLOT_TYPES } from '../../constants';


type ScheduledSlot = {
    dateFrom: string;
    dateTo: string;
    timeSlot: string;
    timeSlot_From: string;
    timeSlot_To: string;
    isAvailable: boolean;
    claimNo: string;
    claimID: number;
    claimAddress: string;
    slotID: number;
    slotStatus: string;
    slotStatusId: number;
    userType: 'Homeowner' | 'Inspector';
    userID: number;
    uname: string;
    asgnCount: number;
};

export type AssignedUser = {
    scheduledUserName?: string;
    schedulerGroupName?: React.ReactElement;
    scheduledUserId?: number;
    scheduledUserIdForCalendar?: string;
    userType?: "Homeowner" | "Inspector";
    assignedCount?: number;
}

export type ClaimSlotsResponse = {
    slots: { [key: string]: Slot };
    scheduledTime: { [key: string]: boolean };
    assignedUsers: { [key: string]: AssignedUser };
    homeownerSlots: { [key: string]: Slot };
}


const formatResponse = (response: ScheduledSlot[]): ClaimSlotsResponse => {


    const formattedResponse: ClaimSlotsResponse = response.reduce((obj: ClaimSlotsResponse, slot,): ClaimSlotsResponse => {

        const fromDate = dayjs.utc(slot.dateFrom)
        const toDate = dayjs.utc(slot.dateTo)





        const id = fromDate.toISOString() + slot.userID + slot.userType;
        obj.slots[id] = {
            id: id,
            title: slot.slotStatus,
            start: fromDate.toDate(),
            end: toDate.toDate(),
            claimNo: slot.claimNo,
            slotId: slot.slotID,
            statusId: slot.slotStatusId,
            scheduledUserId: slot.userID,
            scheduledUserName: slot.uname,
            scheduledUserIdForCalendar: slot.userID + slot.userType,
            userType: slot.userType
        }

        if (slot.userType?.toLocaleLowerCase() === "homeowner") {
            obj.homeownerSlots[fromDate.toISOString()] = obj.slots[id]
        }

        const formattedStart = convertToTimezone(fromDate.toDate()).format("YYYY-MM-DD hh:mm A")
        const formattedEnd = convertToTimezone(toDate.toDate()).format("YYYY-MM-DD hh:mm A")
        obj.scheduledTime[formattedStart] = true
        obj.scheduledTime[formattedEnd] = true

        if (!obj.assignedUsers[slot.userID + slot.userType]) {
            obj.assignedUsers[slot.userID + slot.userType] = {
                scheduledUserId: slot.userID,
                scheduledUserName: slot.uname,
                schedulerGroupName: <div className='d-flex flex-column align-items-center'>
                    <span className='fw-bold'>{slot.userType === "Inspector" ? slot.uname : `${slot.uname} (Homeowner)`}</span>
                    {slot.userType === "Inspector" && <div><small className="text-secondary">Assigned: {slot.asgnCount || 0}</small></div>}
                </div>,
                userType: slot.userType,
                scheduledUserIdForCalendar: slot.userID + slot.userType,
                assignedCount: 0
            }
        }
        if (slot.slotStatusId === SLOT_TYPES.scheduled) {
            obj.assignedUsers[slot.userID + slot.userType].assignedCount += 1
            obj.assignedUsers[slot.userID + slot.userType].schedulerGroupName = <div className='d-flex flex-column align-items-center'>
                <span className='fw-bold'>{slot.userType === "Inspector" ? slot.uname : `${slot.uname} (Homeowner)`}</span>
                {slot.userType === "Inspector" && <div><small className="text-secondary">Assigned: {obj.assignedUsers[slot.userID + slot.userType].assignedCount || 0}</small></div>}
            </div>
        }

        return obj
    }, {
        slots: {},
        scheduledTime: {},
        assignedUsers: {},
        homeownerSlots: {}
    })

    return formattedResponse

}

type Params = { claimId: number; startDate: string; endDate: string };

export const useScheduledSlots = createMutation<ClaimSlotsResponse, Params, AxiosError>({
    mutationFn: async params =>
        axios({
            url: 'SchedulerAssignments/AllSlotsForProfileCalendar',
            method: 'POST',
            params
        }).then(response => formatResponse(response.data?.result || [])),
});
