import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { usePostData } from '../../hooks/use-post-data.hook';
import { LoginReponse } from '../../models/login-response';
import Logo from '../../images/logo-keystone';
import './login.style.scss';
import { InputField } from '../../components/form/input.feild.component';
import { useStorage } from '../../hooks/use-storage';
import { StorageKey, WINDOWS_DOORS_API_URL } from '../../constants';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UserResponse } from '../../models/user';
import { error } from 'console';
import { setAxiosAuthHeaders } from '../../api-client.ts/axios';
import { isEmpty } from 'lodash';

export const HomeOwnerLogin = () => {
    const [currentAuthState, setAuthState] = useStorage(StorageKey.AuthState, null);
    const nav = useNavigate();
    const [otpError, setOtpError] = useState('');

    // const {
    //     submitData,
    //     response: { isSubmitting, result, error },
    // } = usePostData<LoginReponse>('SchedulerAssignments/VerifyOTP', 'POST');

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const params = useParams()
    const guiID = Object.values(params)?.[0]?.replace('homeowner-login/', "")

    const [seconds, setSeconds] = useState(0);
    const [isActive, setIsActive] = useState(false);


    // const handleClick = () => {
    //     setSeconds(300); // Set to 5 minutes (300 seconds)
    //     setIsActive(true);
    // };

    // useEffect(() => {
    //     setSeconds(60); // Set to 5 minutes (300 seconds)
    //     setIsActive(true);
    // }, []);

    // useEffect(() => {
    //     let interval = null;


    //     if (isActive && seconds > 0) {
    //         interval = setInterval(() => {
    //             setSeconds(prev => prev - 1);
    //         }, 1000);
    //     } else if (seconds === 0) {
    //         clearInterval(interval);
    //         setIsActive(false);
    //     }

    //     return () => clearInterval(interval); // Cleanup on unmount
    // }, [isActive, seconds]);



    const initialLoginData = {
        otp: "",
    };
    const signupSchema = Yup.object().shape({
        otp: Yup.string().required('One Time Code is required'),
    });


    const handleSubmit = (values: any) => {
        verifyOtp(values);
        // console.log(values)
        // submitData({
        //     data: { ...values, UserGuid: userGuid }
        // });

    };
    const sendOtp = async () => {
        try {
            // /itemData.homeowner_Email
            if (!isEmpty(guiID)) {
                const emailContent = { email: guiID, userType: 'Homeowner' }
                const response = await fetch(`${WINDOWS_DOORS_API_URL}/api/SchedulerAssignments/EmailVerificationAndSendOTP`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(emailContent),
                });
                const data: any = await response.json();

                if (data.success) {
                    console.log("otp sent successfully!");
                } else {
                    console.log(data.message || 'Failed to send OTP');
                }
            } else {
                console.log("Email Id not found!!");
            }
        } catch (ex) {
            console.log('Failed to send OTP');
        }

    }

    const verifyOtp = async (values: any) => {
        try {
            //03108282-CF69-43CA-A0AB-2D015037E597
            setIsSubmitting(true);
            const response = await fetch(`${WINDOWS_DOORS_API_URL}/api/SchedulerAssignments/VerifyOTP?OTP=${values.otp}&UserGuid=${guiID}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data: UserResponse = await response.json();

            if (data.isSuccess) {
                setAuthState(data.token);
                setAxiosAuthHeaders()
                nav("/homeowner/landing-page", { state: data });
            } else {
                setOtpError('Invalid OTP');
            }
        } catch (ex) {
            setOtpError('Failed to verify OTP');
            setError(true)
        } finally {
            setIsSubmitting(false);
        }
    };
    return (
        <div className="loginWrap">
            <div className="logincontent">
                <h3 className="card-title text-center ">
                    <Logo></Logo>
                </h3>
                <div>
                    <div className="login-container global-container loginWrapper">
                        {otpError && <center><div className="alert alert-danger">{otpError}</div></center>}
                        <div className="card login-form p-4">
                            {error && <div>Failed to login</div>}
                            <LoadingIndicator isLoading={isSubmitting} />
                            <Formik
                                initialValues={initialLoginData}
                                validationSchema={signupSchema}
                                onSubmit={handleSubmit}
                            >
                                {() => (
                                    <Form className="p-2">

                                        <InputField
                                            label="One Time Code"
                                            name="otp"
                                            type="text"
                                        />
                                        <button
                                            id="btnSignIn"
                                            className="btn btn-normal btn-block w-100"
                                            type="submit"
                                        >
                                            Verify One Time Code
                                            {/* {isActive ? ("Verify One Time Code") : ("Resend One Time Code")} */}
                                        </button>
                                        {/* <div className='mt-3'><center>{`Time Remaining: ${Math.floor(seconds / 60)}:${(seconds % 60).toString().padStart(2, '0')}`}</center></div> */}
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
