import { Link } from 'react-router-dom';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';

export const SchedulerMenuComponent = () => {
    return (
        <>
            <Navbar expand="lg" >
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav >
                        <Link to="/" className="nav-link text-dark">
                            Home
                        </Link>

                        <NavDropdown title="Admin" id="basic-nav-dropdown" style={{ marginRight: "10px" }}>
                            <Link to="/users" className="nav-link text-dark-3">
                                Users
                            </Link>
                            <Link to="/roles" className="nav-link text-dark">
                                Roles
                            </Link>

                        </NavDropdown>

                    </Nav>
                </Navbar.Collapse>

            </Navbar >
        </>
    );
};
