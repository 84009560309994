import React, { useState } from 'react';
import { RadioGroup, RadioGroupChangeEvent } from '@progress/kendo-react-inputs';

interface IRadioProps {
    options: IOption[];
    onchange: (value: string) => void


}

export interface IOption { label: string, value: string }

const KendoRadioGroupExample: React.FC<IRadioProps> = (props) => {
    const [selectedValue, setSelectedValue] = useState<string>('1');
    const { options, onchange } = props
    // Handle change event
    const [checked, setChecked] = React.useState(options[0].value);
    const handleChange = (event: RadioGroupChangeEvent) => {
        setSelectedValue(event.value);
        onchange(event.value)
        setChecked(event.value);
    };

    return (
        <div>

            <RadioGroup
                defaultValue={options[0]}
                value={checked}
                data={options}
                // value={selectedValue}
                onChange={handleChange}
                style={{ padding: "10px" }}
                layout="horizontal"  // or "vertical" for vertical layout
            />

        </div>
    );
};

export default KendoRadioGroupExample;
