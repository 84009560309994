import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import CloseIcon from "../../../images/close-icon";

interface Props {
    title?: string;
    children: React.ReactNode;
    onClose?: () => void;
    renderActions?: () => React.ReactNode;
    show: boolean;
}

interface TitleProps {
    title: string;
}

function CustomTitle({ title }: TitleProps) {
    return title && <div>{title}</div>
}

export function Modal({ title, children, onClose, renderActions, show }: Props) {
    return show && <Dialog {...(title && { title: <CustomTitle title={title} /> })} onClose={() => onClose?.()} width='80%' closeIcon={true}>
        {!!onClose && <div style={{ marginBottom: 50 }}>
            <div style={{ position: 'absolute', right: 18, top: 7 }}>
                <button className='slot-close-btn' onClick={() => onClose?.()}>
                    <CloseIcon size={13} color="black" />
                </button>
            </div>
        </div>}
        {children}
        {
            renderActions && <DialogActionsBar>
                {renderActions()}
            </DialogActionsBar>
        }
    </Dialog >
}