import React, { useState, useEffect } from 'react';
import { IClaimRequest } from '../../../models/scheduler/claim-request';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { formatDate, parseDate } from '@progress/kendo-intl';
import { convertToTimezoneWithDateString } from '../../../utils';

interface IRequestStatusProps {
    isErrorRequestStatus: boolean;
    isLoadingRequestStatus: boolean;
    requestStatus: IClaimRequest[];
}

const RequestStatus: React.FunctionComponent<IRequestStatusProps> = ({
    isErrorRequestStatus,
    isLoadingRequestStatus,
    requestStatus
}) => {

    const getBadgeClass = (status: string) => {
        return status === 'Scheduled' || status === 'available' || status === 'Availability Received'
            ? 'green-color'
            : status === 'Awaiting Availability' || status === 'requested' || status === 'Pending Schedule'
                ? 'orange-color'
                : status === 'Declined'
                    ? 'red-color'
                    : 'black-color';
    };

    return (
        <>
            {isLoadingRequestStatus && <LoadingIndicator isLoading={true} />}
            {isErrorRequestStatus && <center>Loading Error</center>}

            {!isLoadingRequestStatus && requestStatus && (
                <div className='card border-0 sw-shadow radius-15 overflow-hidden p-3'>
                    <div style={{ maxHeight: '393px', overflowY: 'auto' }}>
                        <div className="text-grid-header sticky-top">
                            <div>Name</div>
                            <div>Requested On</div>
                            <div>Status</div>
                        </div>
                        {(requestStatus?.length) > 0 ?

                            requestStatus?.map((e, index) => (
                                <div className="text-grid-body" key={index}>
                                    <div>{e.name}</div>
                                    {/* <div>{formatDate(parseDate(e.requestedOn), "MM/dd/yyyy hh:mm a")}</div> */}
                                    <div>{convertToTimezoneWithDateString(e?.requestedOn).format('MM/DD/YYYY hh:mm A')}</div>
                                    <div>
                                        <span className={`statusbadge ${getBadgeClass(e.status)}`} key={e.name}>
                                            {e.status}
                                        </span>
                                    </div>
                                </div>
                            )) :
                            (<div className='mt-4'><center><h5>Request status not found</h5></center></div>)}

                    </div>
                </div>
            )}
        </>
    );
};

export default RequestStatus;
