import React, { useMemo, useState } from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useLoadData } from '../../../hooks/use-scheduler-load-data';
import { IClaimRequest } from '../../../models/scheduler/claim-request';
import { IClaimProfile } from '../../../models/scheduler/claim-profile';
import { isEmpty } from 'lodash';
import { GoogleMapValue } from '../../../models/scheduler/map-value';

const containerStyle = {
    width: '100%',
    height: '200px'
};
interface IProfileMap {
    claimDetails: IClaimProfile,
}

const ProfileMap: React.FunctionComponent<IProfileMap> = (props) => {
    const { claimDetails } = props;

    const [address, setAddress] = useState<string>("");
    let defaultMapValue = new GoogleMapValue();
    defaultMapValue.lat = 38.7946;
    defaultMapValue.lng = 106.5348;
    const [center, setCenter] = useState<GoogleMapValue>(defaultMapValue);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBKDeDCyCAHKPru29FgkbODlVNwQGEzDPg"
    });
    React.useEffect(() => {
        if (claimDetails) {
            if (!isEmpty(claimDetails?.lat) && !isEmpty(claimDetails?.long)) {
                const map = new GoogleMapValue();
                map.lat = parseFloat(claimDetails.lat.toString());
                map.lng = parseFloat(claimDetails.long.toString());
                setCenter(map);
                setAddress(claimDetails.homeOwner_Address);
            }
        }
    }, [claimDetails])
    return isLoaded ? (
        <div className='card border-0 sw-shadow radius-15 overflow-hidden'>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={18}
            >
                {/* Mark location */}
                <Marker position={center} />
                <></>
            </GoogleMap>
            <div className='text-center p-2'>{address}</div>
        </div>
    ) : <></>
}
export default ProfileMap;