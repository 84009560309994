
const LocationIcon = () => {
    return (<svg
        className="me-2"
        width="13.706"
        height="19.619"
        viewBox="0 0 13.706 19.619"
    >
        <g
            id="Group_762"
            data-name="Group 762"
            transform="translate(-12.756 -9.839)"
        >
            <path
                id="Path_314"
                data-name="Path 314"
                d="M16.069,6.327A6.578,6.578,0,0,0,14.017,6,6.88,6.88,0,0,0,8.79,8.45l3.257,2.711Z"
                transform="translate(5.604 3.839)"
                fill="#1a73e8"
            />
            <path
                id="Path_315"
                data-name="Path 315"
                d="M9.428,7.5A6.828,6.828,0,0,0,7.79,11.9a7.991,7.991,0,0,0,.671,3.275l4.176-4.912Z"
                transform="translate(4.966 4.78)"
                fill="#ea4335"
            />
            <path
                id="Path_316"
                data-name="Path 316"
                d="M12.765,10.065a2.607,2.607,0,0,1,1.846.747,2.531,2.531,0,0,1,.758,1.819,2.449,2.449,0,0,1-.618,1.6s2.051-2.422,4.069-4.811a6.762,6.762,0,0,0-3.987-3.3L10.78,10.931a2.556,2.556,0,0,1,1.985-.866"
                transform="translate(6.872 4.071)"
                fill="#4285f4"
            />
            <path
                id="Path_317"
                data-name="Path 317"
                d="M14.4,13.447a2.6,2.6,0,0,1-2.62-2.62,2.505,2.505,0,0,1,.622-1.637L8.23,14.1a23.285,23.285,0,0,0,3.111,4.421L16.4,12.5a2.571,2.571,0,0,1-2,.95"
                transform="translate(5.246 5.858)"
                fill="#fbbc04"
            />
            <path
                id="Path_318"
                data-name="Path 318"
                d="M15.111,20.77c2.292-3.57,4.912-5.207,4.912-9.35a6.828,6.828,0,0,0-.77-3.16L10.1,19.116c.393.524.77,1.048,1.163,1.637,1.392,2.145,1,3.439,1.9,3.439s.459-.943,1.8-3.2"
                transform="translate(6.438 5.266)"
                fill="#34a853"
            />
        </g>
    </svg>)
}
export default LocationIcon;