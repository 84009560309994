import type { AxiosError } from 'axios';
import axios from 'axios';
import { createMutation } from 'react-query-kit';

type Response = {
    claimID: number;
    homeOwnerStatusID: number;
    inspectorStatusId: number;
};


type Params = { claimId?: number; fromDate: string; toDate: string; inspectorId?: number; homeownerId?: number };

export const useCheckScheduleStatus = createMutation<Response, Params, AxiosError>({
    mutationFn: async params =>
        axios({
            url: 'SchedulerAssignments/GetClaimScheduleStatusForCalendar',
            method: 'POST',
            params: params
        }).then(response => response.data?.result?.[0]),
});
