import { DataResult, process, State } from '@progress/kendo-data-query';
import { setExpandedState, setGroupIds } from '@progress/kendo-react-data-tools';
import {
    Grid,
    GridCellProps,
    GridColumn,
    GridColumnMenuFilter,
    GridColumnMenuProps,
    GridDataStateChangeEvent,
    GridExpandChangeEvent,
} from '@progress/kendo-react-grid';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    DeleteModelDialog,
    DeletePostDataInfo,
    DeleteStatus,
} from '../../../components/delete-model-dialog.component';
import { SubmitStatus } from '../../../components/formik-model-dialog.component';
import { GridCommandCell } from '../../../components/grid-command-cell.component';
import { GuardedComponent } from '../../../components/guarded.componemt';
import { LoadingIndicator } from '../../../components/loading-indicator.component';
import { useLoadData } from '../../../hooks/use-load-data.hook';
import { PermissionName } from '../../../models/user-security/permission.name';
import { User } from '../../../models/user-security/user';
import { UserRole } from '../../../models/user-security/user-role';
import { addOrUpdateArrayItem, deleteArrayItem } from '../../../utils/array.util';
import { EditUser } from './edit-user.component';

export const UsersPage = () => {
    const initialDataState: State = {
        take: 10,
        skip: 0,
        group: [],
    };

    const processWithGroups = (data: User[], dataState: State) => {
        const groups = dataState.group;
        dataState.group = groups;
        const newDataState = process(data, dataState);
        setGroupIds({ data: newDataState.data, group: dataState.group });
        return newDataState;
    };

    const history = useNavigate();
    const [editUserData, setEditUserData] = useState<User>();
    const [deleteUserData, setDeleteUserData] = useState<DeletePostDataInfo>();
    const { isLoading, result, isError } = useLoadData<User[]>(`users/list`);
    const [collapsedState, setCollapsedState] = useState<string[]>([]);
    const [resultData, setResultData] = useState<DataResult>();
    const [dataState, setDataState] = useState<State>(initialDataState);


    useEffect(() => {
        if (result) {
            setResultData(processWithGroups(result, initialDataState));
        }
    }, [result]);

    const onAddNew = () => {

        const user = new User();
        setEditUserData(user);
    };

    const onEdit = (data: User) => {
        setEditUserData(data);
    };

    const onRemove = (data: User) => {
        const { userGUID } = data;
        setDeleteUserData({
            data,
            route: `users/delete/${userGUID}`,
        });
    };

    const onSaveComplete = (status: SubmitStatus, data: User) => {
        if (status === SubmitStatus.Completed) {
            const newData = addOrUpdateArrayItem(result, data, 'userGUID');
            setResultData(processWithGroups(newData, dataState));
        }
        setEditUserData(null);
    };

    const onDeleteComplete = (status: DeleteStatus, data: User) => {
        if (status === DeleteStatus.Completed) {
            const newData = deleteArrayItem(result, data, 'userGUID');
            setResultData(processWithGroups(newData, dataState));
        }
        setDeleteUserData(null);
    };

    const CommandCell = (props: GridCellProps) => (
        <GridCommandCell
            {...props}
            edit={onEdit}
            remove={onRemove}
            add={onAddNew}
            idPropertyName="userGUID"
            editPermissionName={PermissionName.EditUser}
            deletePermissionName={PermissionName.DeleteUser}
        />
    );

    const ColumnMenu = (props: GridColumnMenuProps) => {
        return (
            <div>
                <GridColumnMenuFilter {...props} />
            </div>
        );
    };

    const expandChange = (event: GridExpandChangeEvent) => {
        const item = event.dataItem;

        if (item.groupId) {
            const newCollapsedIds = !event.value
                ? [...collapsedState, item.groupId]
                : collapsedState.filter((groupId) => groupId !== item.groupId);
            setCollapsedState(newCollapsedIds);
        }
    };

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        const newDataState = processWithGroups(result, event.dataState);
        setResultData(newDataState);
        setDataState(event.dataState);
    };

    const RolesCell = (props: any) => {
        return <td>{props.dataItem[props.field].map((p: UserRole) => p.roleName).join(', ')}</td>;
    };

    let newData = null;
    if (resultData) {
        newData = setExpandedState({
            data: resultData.data,
            collapsedIds: collapsedState,
        });
    }

    let grid = null;
    if (newData) {
        grid = (
            <Grid
                data={newData}
                pageable={{ pageSizes: [10, 20, 50, 100] }}
                total={resultData.total}
                groupable={true}
                sortable={true}
                onDataStateChange={dataStateChange}
                {...dataState}
                onExpandChange={expandChange}
                expandField="expanded"
            >
                <GridColumn field="fullName" title="NAME" columnMenu={ColumnMenu} />
                <GridColumn field="emailAddress" title="Email" columnMenu={ColumnMenu} />
                <GridColumn field="userName" title="User Name" columnMenu={ColumnMenu} />
                <GridColumn field="userRoles" title="Roles" cell={RolesCell} />
                <GridColumn cell={CommandCell} width="120px" groupable={true} />
            </Grid>
        );
    }

    if (isError) {
        return <div>Error</div>;
    }
    return (
        <div className="container-fluid">
            <div className="top-sec-align">
                <h5 className="text-normal title-web mb-0">USERS</h5>
                <GuardedComponent permission={PermissionName.AddUser}>
                    <button
                        title="Add new"
                        className="btn btn-primary btn-sm ms-auto"
                        onClick={onAddNew}
                    >
                        Add new
                    </button>
                </GuardedComponent>
            </div>
            <LoadingIndicator isLoading={isLoading} />
            <div className="card fixbuttons">{newData && <>{grid}</>}</div>
            {editUserData && <EditUser data={editUserData} onClose={onSaveComplete} />}
            {deleteUserData && (
                <DeleteModelDialog
                    title="Delete User"
                    deleteData={deleteUserData}
                    onClose={onDeleteComplete}
                >
                    <div>
                        Are you sure you want to delete User - {deleteUserData.data.userName}?
                    </div>
                </DeleteModelDialog>
            )}
        </div>
    );
};
