import { useState } from "react";
import SingleDropdown, { DropdownDataItemProps } from "./single-select-dropdown";
import { SLOT_TYPES } from "../../../constants";
import { LoadingIndicator } from "../../../components/loading-indicator.component";


const AVAILABILITY_SLOT_TYPES: DropdownDataItemProps[] = [{
    text: 'Available',
    value: SLOT_TYPES.availabilityReceived
},
{
    text: 'Unavailable',
    value: SLOT_TYPES.unavailable
}]


interface Props {
    isLoading: boolean;
    onAddAvailability: (slotTypeId: number) => void
}

export function AddAvailability({ isLoading, onAddAvailability }: Props) {

    const [selectedSlotTypeId, setSelectedSlotTypeId] = useState<number>()
    const [error, setError] = useState<string>("")

    const handleAddAvailability = () => {
        setError("")

        if (!selectedSlotTypeId || selectedSlotTypeId?.toString() === "-1") {
            setError("Please select slot type");
            return
        }
        onAddAvailability(selectedSlotTypeId)

    }


    return <div style={{ background: 'rgb(240 240 240)', padding: 10 }}><div style={{ display: 'flex', gap: 20, flex: 1, justifyContent: 'flex-start', alignItems: 'center' }}>
        <SingleDropdown
            id="inspector"
            onchange={(slotTypeId: number) => {


                setSelectedSlotTypeId(slotTypeId)
            }}
            dataitems={AVAILABILITY_SLOT_TYPES}
        />
        <button className="btn btn-outline-primary btn-sm me-1" onClick={handleAddAvailability} disabled={isLoading}>
            {isLoading ? <LoadingIndicator isLoading={isLoading} showLabel={false} size='sm' /> : "Add Availability"}
        </button>
    </div>
        {!!error && <span className="text-danger">{error}</span>}
    </div>
}