import React, { useState } from 'react';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import '@progress/kendo-theme-default/dist/all.css';
import { text } from 'stream/consumers';
interface DataItem {
    text: string;
    value: string;
}
interface SingleDropdownProps {
    dataitems: DataItem[];
    onchange: Function;
    id: string;
    placeholder?: string;
    isDef?: DataItem;
}

const SingleDropdown: React.FC<SingleDropdownProps> = ({ ...props }) => {
    const { onchange, id, dataitems, placeholder = "Select", isDef } = props;
    const [selectedItem, setSelectedItem] = useState<DataItem | null>();
    const handleChange = (event: DropDownListChangeEvent) => {
        setSelectedItem(event.target.value as DataItem);
        onchange(event.target.value.value as string);
    };

    return (
        <div>
            <DropDownList fillMode='solid'
                data={dataitems}
                id={id}
                key={id}
                name={id}
                textField="text"
                dataItemKey="value"
                defaultItem={isDef ? isDef : { text: "Select Option", value: "-1" }}
                onChange={handleChange}
                value={selectedItem}
                className='form-control dropDownList'
            />
            {/* {selectedItem && <div>Selected option: {selectedItem.text}</div>} */}
        </div>
    );
};

export default SingleDropdown;
