import { Route, Routes } from "react-router-dom";
import HomeOwnerLandingDetails from "../features/scheduler/home-owner-landing-details";
import { HeaderComponent } from "./header.component";
import { AuthorizeComponent } from "./authorize.component";
import { HomeOwnerLogin } from "../features/login/login-home-owner";
import HomeOwnerAvailability from "../features/scheduler/home-owner-availability";

export function HomeOwnerRoutes() {
    return (
        <AuthorizeComponent role='homeowner'>
            <HeaderComponent showMenu={false} disableLogoClick />
            <Routes>
                <Route
                    path="/landing-page"
                    element={<HomeOwnerLandingDetails />}
                />
                <Route
                    path="/homeowner-login/:GuiID"
                    element={<HomeOwnerLogin />}
                />
                <Route
                    path="/homeowner-availability"
                    element={<HomeOwnerAvailability />}
                />
            </Routes>
        </AuthorizeComponent>)
}