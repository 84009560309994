import React from 'react';


export interface Step {
    status: string;
    label: string;
}

interface StepperProps {
    steps: Step[];
}

const Stepper: React.FC<StepperProps> = ({ steps }) => {
    return (
        <div>
            <ul className="cus-Stepper">
                {steps.map((step: Step, index: number) => (
                    <li key={index} className={step.status}>
                        <span className="icon"></span>
                        <span>{step.label}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Stepper;
