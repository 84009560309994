import { useEffect, useState } from 'react';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import './login.style.scss';
import SingleDropdown from '../../components/single-select-dropdown';
import { useStorage } from '../../hooks/use-storage';
import { StorageKey, WINDOWS_DOORS_API_URL } from '../../constants';
import { UserResponse } from '../../models/user';
import { useNavigate } from 'react-router-dom';
import { setAxiosAuthHeaders } from '../../api-client.ts/axios';

interface LoginResponse {
    success: boolean;
    token?: string;
    errorMessage?: string;
    message?: string;
    statusCode?: number;
    result?: string;
}

export const MobileLogin = () => {
    const [currentAuthState, setAuthState] = useStorage(StorageKey.AuthState, null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState('');
    const [error, setError] = useState('');
    const [userGuid, setUserGuid] = useState('');
    const nav = useNavigate();

    useEffect(() => {
        if (currentAuthState) {

        }
    }, [currentAuthState]);

    const sendOtp = async (values: { mobileNo: string; countryCode: string; userType: string }) => {
        setIsSubmitting(true);
        values.userType = "Inspector";
        try {
            const response = await fetch(`${WINDOWS_DOORS_API_URL}/api/SchedulerAssignments/MobileVerificationAndSendOTP`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });
            const data: LoginResponse = await response.json();

            if (data.success) {
                setUserGuid(data?.result);
                setShowOtpInput(true);
            } else {
                setError(data.errorMessage || 'Failed to send OTP');
            }
        } catch (ex) {
            setError('Failed to send OTP');
        } finally {
            setIsSubmitting(false);
        }
    };

    const verifyOtp = async () => {
        setIsSubmitting(true);
        try {
            const response = await fetch(`${WINDOWS_DOORS_API_URL}/api/SchedulerAssignments/VerifyOTP?OTP=${otp}&userGuid=${userGuid}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data: UserResponse = await response.json();
            if (data.isSuccess) {
                setAuthState(data.token);
                setAxiosAuthHeaders()

                nav('/inspector/landing-page', { state: { ...data } })
            } else {
                setOtpError('Invalid OTP');
            }
        } catch (ex) {
            console.log(ex);
            setOtpError('Failed to verify OTP');

        } finally {
            setIsSubmitting(false);
        }
    };

    const handleSubmit = async (values: { mobileNo: string; countryCode: string; userType: string }) => {
        if (showOtpInput) {
            await verifyOtp();
        } else {
            await sendOtp(values);
        }
    };

    const initialLoginData = {
        mobileNo: '',
        countryCode: '+1',
        userType: ''
    };

    const signupSchema = Yup.object().shape({
        mobileNo: Yup.string()
            .matches(/^\d{10}$/, 'Enter a valid 10 digit mobile number')
            .required('Mobile number is required'),
        countryCode: Yup.string().required('Country code is required'),
    });

    return (
        <>
            {error && <div className="alert alert-danger">{error}</div>}
            {otpError && <div className="alert alert-danger">{otpError}</div>}
            <LoadingIndicator isLoading={isSubmitting} />
            <Formik
                initialValues={initialLoginData}
                validationSchema={signupSchema}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue }) => (
                    <Form className="form-content">
                        <div className="row mb-3 mt-3 align-items-center">
                            <div className="col-auto pr-0 mx-0">
                                <label htmlFor="countryCode">Country Code</label>
                                <SingleDropdown
                                    dataitems={[
                                        { text: '+1', value: '+1' },
                                        { text: '+91', value: '+91' },
                                    ]}
                                    id="countryCode"
                                    onchange={(value: string) => setFieldValue('countryCode', value)}
                                //isDef={{ text: '+1', value: '+1' }}
                                />
                            </div>
                            <div className="col pl-0">
                                <label htmlFor="mobile">Mobile</label>
                                <Field
                                    className="form-control form-control-md"
                                    name="mobileNo"
                                    placeholder="Enter mobile number"
                                />

                            </div>

                        </div>
                        <div className="mt-2">  <small className="errorMsg">
                            <ErrorMessage name="mobileNo" />
                        </small></div>
                        {showOtpInput && (
                            <div className="row mb-3 align-items-center">
                                <div className="col">
                                    <label htmlFor="otp">OTP</label>
                                    <Field
                                        className="form-control form-control-md"
                                        name="otp"
                                        placeholder="Enter One Time Code"
                                        value={otp}
                                        onChange={(e: any) => setOtp(e.target.value)}
                                    />
                                    <p className="mt-2" style={{ fontSize: "12px", textAlign: "center" }}>(This One Time Code will be valid for the next 5 mins.)</p>
                                </div>
                            </div>
                        )}

                        <button
                            id="btnSignIn"
                            className="btn btn-normal btn-block w-100 mt-2"
                            type="submit"
                            disabled={isSubmitting}
                        >
                            {showOtpInput ? 'Verify OTP' : 'Send OTP'}
                        </button>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default MobileLogin;
