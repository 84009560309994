import React, { useEffect, useState } from 'react';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import '@progress/kendo-theme-default/dist/all.css';
export interface DropdownDataItemProps {
    text: string;
    value: string | number;

}
interface SingleDropdownProps {
    dataitems: DropdownDataItemProps[];
    onchange: Function;
    id: string;
    placeholder?: string;
    isDef?: DropdownDataItemProps;
}

const SingleDropdown: React.FC<SingleDropdownProps> = ({ ...props }) => {
    const { onchange, id, dataitems, placeholder = "Select", isDef } = props;
    const [selectedItem, setSelectedItem] = useState<DropdownDataItemProps | null>(isDef ? isDef : undefined);

    useEffect(() => {
        setSelectedItem(isDef)
    }, [isDef])


    const handleChange = (event: DropDownListChangeEvent) => {
        setSelectedItem(event.target.value as DropdownDataItemProps);
        onchange(event.target.value.value as string, event.target.value as DropdownDataItemProps);
    };

    return (
        <div>
            <DropDownList
                data={dataitems}
                id={id}
                key={id}
                name={id}
                textField="text"
                dataItemKey="value"
                defaultItem={!isDef ? { text: "Select Option", value: "-1" } : selectedItem}
                onChange={handleChange}
                value={selectedItem}
            />
            {/* {selectedItem && <div>Selected option: {selectedItem.text}</div>} */}
        </div>
    );
};

export default SingleDropdown;
