import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { usePostData } from '../../hooks/use-post-data.hook';
import { LoginReponse } from '../../models/login-response';
import Logo from '../../images/logo-keystone';
import './login.style.scss';
import { InputField } from '../../components/form/input.feild.component';
import { useStorage } from '../../hooks/use-storage';
import { StorageKey } from '../../constants';
import { RadioButton, RadioGroup } from '@progress/kendo-react-inputs';
import KendoRadioGroupExample from './radio-group.component';
import MobileLogin from './login-mobile';
import EmailLogin from './login-email';

export const InspectorLogin = () => {
    const [logintype, setLoginType] = useState<string>("mobile");
    // Define the options for the RadioGroup
    const options = [
        { label: 'Mobile', value: 'mobile' },
        { label: 'Email', value: 'email' },

    ];

    return (
        <div className="loginWrap">
            <div className="logincontent">
                <h3 className="card-title text-center ">
                    <Logo></Logo> {/* <img src={Logo} alt="logo" className="logo" /> */}
                </h3>
                <div>
                    <div className="login-container global-container loginWrapper">
                        <div className="card login-form p-4">

                            <KendoRadioGroupExample
                            
                            onchange={(v) => {
                                setLoginType(v);
                            }} options={options}></KendoRadioGroupExample>

                            {logintype === "mobile" && (<MobileLogin />)}
                            {logintype === "email" && (<EmailLogin />)}


                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
